import React, { useEffect, useState } from "react";
import useErrorPage from "../../hooks/useErrorPage";
import { postQuizAnswers } from "../../services/QuizService";
import { QuizQuestion } from "../../models/QuizQuestion";
import { QuizApplyModel } from "../../models/QuizApplyModel";
import { QuizModel } from "../../models/QuizModel";
import { ResponseModel } from "../../models/ResponseModel";
import { Button } from "../Shared/Buttons/Button";
import { CountdownTimer } from "./CountdownTimer";
import { RadioInput } from "../Shared/Inputs/RadioInput";
import { QuizFormLoader } from "../Loaders/QuizFormLoader";

interface Props {
    styles: any;
    quiz: QuizModel;
    questions: QuizQuestion[];    
    finishQuiz(quizApplyModel: QuizApplyModel): Promise<any>;
    openAlert(status: string, message: string): void;
}

export const QuizForm: React.FC<Props> = (props) => {
    const styles = props.styles;
    const quiz = props.quiz;        
    const [page, setPage] = useState<number>(1);
    const [quizQuestions, setQuizQuestions] = useState<QuizQuestion[]>(props.questions);
    const [submittedCount, setSubmittedCount] = useState<number>(0);
    const [stopCountdown, setStopCountdown] = useState<boolean>(false);    
    const [loading, setLoading] = useState<boolean>(false);    
    const [toErrorPage] = useErrorPage();

    const handleAnswerQuestion = (questionIndex: number, answerIndex: number) => {    
        let tempQuestions = [...quizQuestions];                
        tempQuestions[questionIndex].choiceAnswers!.forEach((e) => {e.selected = false});
        tempQuestions[questionIndex].choiceAnswers![answerIndex!].selected = true;
        tempQuestions[questionIndex].candidateAnswer = tempQuestions[questionIndex].choiceAnswers![answerIndex!].answer;            
        setQuizQuestions(tempQuestions);
    }

    useEffect(() => {                               
        window.scrollTo(0, 0);                                   
    }, [page])

    async function saveAnswers ()
    {
        let answeredCount = quizQuestions.filter(x => x.candidateAnswer).length;
        if(answeredCount === quizQuestions.length) {        
            setLoading(true);
            let quizApplyModel: QuizApplyModel = {
                quizId: quiz.quizId,
                jobQuizId: quiz.jobQuizId,
                candidateId: quiz.candidateId,
                jobApplicationId: quiz.jobApplicationId,
                questions: quizQuestions,
                nextPageNumber: page + 1
            }            
            await postQuizAnswers(quizApplyModel)
            .then((response: ResponseModel) => {
                if(response.status) {
                    setPage(page + 1);
                    setSubmittedCount(submittedCount + quizQuestions.length);
                    setQuizQuestions(response.data);                
                }
                else {
                    props.openAlert("error", response.message);
                }
                setLoading(false);
            })
            .catch((error) => {            
                toErrorPage(error);
            });
        }
        else {
            props.openAlert("error", "Please answer all the Questions");
        }
    }

    const handleSubmit = () => {        
        let answeredCount = quizQuestions.filter(x => x.candidateAnswer).length;
        if(answeredCount === quizQuestions.length) { 
            setStopCountdown(true);
        }
        else {
            props.openAlert("error", "Please answer all the Questions");
        }
    };

    const submitQuiz = (elapsedDuration: number) => {
        let quizApplyModel: QuizApplyModel = {
            quizId: quiz.quizId,
            jobQuizId: quiz.jobQuizId,
            candidateId: quiz.candidateId,
            jobApplicationId: quiz.jobApplicationId,
            questions: quizQuestions,
            duration: elapsedDuration
        }
        props.finishQuiz(quizApplyModel);
    }

    const timeoutQuiz = () => {                   
        let quizApplyModel: QuizApplyModel = {
            quizId: quiz.quizId,
            jobQuizId: quiz.jobQuizId,
            candidateId: quiz.candidateId,
            jobApplicationId: quiz.jobApplicationId,
            questions: quizQuestions,
            duration: quiz.duration
        }
        props.finishQuiz(quizApplyModel);        
    };    

    return (
        <div style={styles.wrapper}>       
            <div style={styles.infoWrapper}>
                <div style={styles.rowWrapper}>
                    <div style={styles.info}><span style={styles.bold}>{page}</span> out of <span style={styles.bold}>{quiz.pageCount}</span> pages</div>
                    <CountdownTimer style={styles.countdown} duration={quiz.duration} stop={stopCountdown} submit={submitQuiz} onTimeout={timeoutQuiz}/>
                </div>
                <hr style={styles.line}/>
                <div style={styles.info}><span style={styles.bold}>{submittedCount}</span> out of <span style={styles.bold}>{quiz.questionCount}</span> submitted</div>
            </div>           
            {loading ? <QuizFormLoader/> : quizQuestions.map((question, questionIndex) =>         
                <div key={questionIndex} style={styles.questionWrapper}>
                    <p style={styles.question}>
                        <span style={styles.bold}>{submittedCount + questionIndex + 1 + "."}</span> &nbsp;
                        <span dangerouslySetInnerHTML={{ __html: question.question }}></span>                                                                      
                    </p>
                    <div>
                        {question.choiceAnswers!.map((choiceAnswer, answerIndex) =>
                            <RadioInput id={`singleChoice_${questionIndex}_${answerIndex}`} name={"singleChoice_#" + questionIndex} key={answerIndex} styles={styles.selectAnswer} value={choiceAnswer.answer} checked={choiceAnswer.selected} handleChange={() => handleAnswerQuestion(questionIndex, answerIndex)}>
                                {choiceAnswer.answer}
                            </RadioInput>
                        )}
                    </div>
                </div>  
            )} 
            {(quiz.pageCount === page) ? <Button style={{...styles.button, ...styles.button.submit}} onClick={handleSubmit}>FINISH & SUBMIT</Button> : <Button style={styles.button} onClick={saveAnswers}>NEXT</Button>}            
        </div>
    );
};
