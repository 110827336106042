import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const jobApplyPage = {
    mobile: {
        mainWrapper: {
            display: "block",
            margin: "4.83vw"
        },
        rightWrapper: {
            borderLeft: "none"
        },
        applyStatus: {
            display: "flex",
            boxShadow: "5px 5px 45px #00000029",
            borderRadius: "1.40vw",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "-20.53vw",
            width: "67.14vw",
            height: "14.73vw",
            backgroundColor: Color.White,
            position: "relative" as "relative",
            applyComplete:{
                marginTop: "-8.69vw"
            },
            boxHolder: {
                width: "11.352vw",
                height: "11.352vw",
                margin: "auto",
                borderRadius: "1.20vw",
                position: "absolute" as "absolute",
                top: "0.78vh",
                bottom: "0.78vh",
                zIndex: "1",
                active: {
                    backgroundColor: Color.LightBlue,
                },
                applying: {
                    left: "2.41vw"
                },
                screening: {
                    left: "0",
                    right: "0"
                },
                applied: {
                    right: "2.41vw"
                }
            },
            box: {
                width: "8.93vw",
                height: "8.93vw",
                borderRadius: "1.20vw",
                margin: "1.2vw",
                backgroundColor: Color.LightAsh,                
                textAlign: "center" as "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                active: {
                    backgroundColor: Color.Blue
                },
                pass: {
                    backgroundColor: Color.DeepBlack
                }
            },
            icon: {
                width: "3.86vw",
                height: "3.86vw",
                fill: Color.MiddleAsh,
                apply: {
                    width: "3.14vw",
                    height: "3.86vw"
                },
                screening: {
                    width: "3.14vw",
                    height: "4.58vw"
                },
                applied: {
                    width: "5.31vw",
                    height: "5.07vw"
                },           
                active: {
                    fill: Color.LightBlue
                },
                pass: {
                    fill: Color.White
                }
            },      
            progressBar: {
                position: "absolute" as "absolute",
                width: "40%",
                transform: "rotate(90degree)",
                top: "35%",
                height: "1px",
                backgroundImage: "linear-gradient(90deg, #D6D5D6 25%, transparent 25%, transparent 50%, #D6D5D6 50%, #D6D5D6 75%, transparent 75%, transparent 100%)",
                backgroundSize: "20px 100%",
                border: "none",
                left: {
                    left: "10%"
                },
                right: {
                    right: "10%"
                },
                extendRight: {
                    right: "10%",
                    width: "80%"
                },
                active: {
                    backgroundImage: "linear-gradient(90deg, #0B132B 25%, transparent 25%, transparent 50%, #0B132B 50%, #0B132B 75%, transparent 75%, transparent 100%)"
                }
            },
            cvLess: {
                width: "40.82vw",
                marginTop: "-20.39vw",
            },
            cvLessProgressBar: {
                left: "10%",
                width: "80%"
            },
            filteringBox: {
                width: "40.82vw"
            },          
        },
        verticalJobDetailCard: {
            height: "auto",
            marginTop: "70px",
            border: "2px solid " + Color.LightAsh,
            hideSummary: {
                height: "35vw",
                line: {
                    display: "none"
                },
                summary: {
                    display: "none"
                }
            },
            apply: {
                display: "none"
            },
            line: {
                display: "block"
            },
            lineTwo: {
                display: "none"
            },
            summaryButtonWrapper: {
                display: "none"
            },
            share: {
                display: "none"
            },
            summary: {
                marginTop: "-3vw",
                display: "block",
                
                item: {
                    marginLeft: "-0.3vw",
                    disply: "block"
                }, 
                key: {
                    marginRight: "-5vw"
                },
                value: {
                    paddingLeft: "4vw"            
                },
            },
            overview: {
                display: "none"
            },
        },
        organizationCard: {
            display: "none",
            marginTop: "2.77vh",
            marginLeft: "0",
            favouriteButton: {
                display: "none"
            },
            notifyButton: {
                display: "none"
            }
        },
        applyForm: {
            inputGroup: {
                display: "block",
                fontType: Font.Poppins,
                fontWeight: 400,
                color: Color.DeepAsh,
                borderBottom: "0.11vw solid " + Color.Gainsboro,
                paddingTop: "2%",
                paddingBottom: "3%"
            },
            inputContainer: {
                fontFamily: Font.Poppins
            },
            label: {
               marginLeft: "3.62vw"
            },
            inputField: {
                marginTop: "1.20vw",
                marginLeft: "0",
                marginBottom: "4vw",
                inputField: {
                    fontFamily: Font.Poppins
                }
            },
            applyStage: {
                color: Color.LightBlack,
                fontSize: "6.04vw"
            },
            applicationStage: {                
                fontWeight: "bold" as "bold",
                color: Color.LightBlack,
                fontSize: "6.03vw",
                margin: "5.58vh 0 0 0",
                letterSpacing: "0px"
            },
            stageDescription: {
                fontSize: "3.38vw",
                letterSpacing: "0px",
                color: Color.MiddleBlack,
                lineHeight: "4.8vw",
                margin: "-5vw 0 6vw 0"
            },
            resumeSectionTexts: {
                width: "unset",                
                fontSize: "3.86vw",
                color: Color.DeepAsh,
                marginTop: "3.34vw"
            },
            cvTxtLabel: {
                fontFamily: Font.Poppins
            },
            cvDescription: {
                color: Color.MiddleBlack,
                fontSize: "3.38vw",
                margin: "1.67vh 0 2.79vh 0",
                lineHeight: "4.5vw"
            },
            coverLetterLabel: {                
                color: Color.DeepAsh,
                fontFamily: Font.Poppins,
                fontWeight: 400,
                fontSize: "3.86vw",
                margin: " 5.2vw 0 0.55vh 3.26vw"
            },
            uploadedCVText: {
                display: "none"
            },
            uploadedCV: {                
                fontWeight: "bold" as "bold",
                marginTop: "1%",
                color: Color.Black,
                display: "none"
            },
            cvHolder: {
                display: "flex",
                justifyContent: "center",
                marginBottom: "6vw"
            },
            viewCV: {
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                textTransform: "none",
                fontSize: "2.50vw",
                fontWeight: "0"
            },
            viewCVIcon: {
                marginLeft: "2vw",
                marginBottom: "0.3vw",
                width: "2.5vw",
                height: "2.5vw",
                stroke: Color.White
            },
            existingCV: {
                width: "43.96vw",
                height: "60.38vw",
                position: "relative" as "relative",
                border: "1px solid " + Color.MiddleAsh,
                borderRadius: "2.41vw"
            },
            existingCvLayer: {
                position: "absolute" as "absolute",
                bottom: "0",
                left: "0",
                top: "0",
                right: "0", 
                background: "transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box",                
                borderRadius: "2.41vw"
            },
            existingCvLabel: {
                position: "absolute" as "absolute",
                bottom: "8%",
                left: "10%",
                fontSize: "14px",                
                color: Color.White,
                textTransform: "lowercase",
                letterSpacing: "0px"
            },
            existingCvName: {
                fontSize: "3.3vw",
                marginBottom: "7px",
                fontWeight: "0"
            },
            documentViewer: {
                height: "100%",
                borderRadius: "2.41vw",
            },
            uploadCV: {
                width: "43.96vw",
                height: "60.38vw",
                border: "0.1vw dashed" + Color.DarkGray,
                borderRadius: "0.52vw",
                marginLeft: "2.41vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            },
            coverLetterEditor: {
                height: "unset",
                minHeight: "60.86vw",
                border: "0.24vw solid" + Color.MiddleAsh
            },
            uploadCVTexts: {
                fontSize: "3.38vw",
                color: Color.SharpAsh,
                textAlign: "center",
                width: "25vw",
                lineHeight: "3.62vw"
            },
            uploadCVMaxFileSize: {
                fontFamily: Font.Poppins,
                fontSize: "2.41vw",
                margin: "1.40vw 0 0 0"
            },
            uploadCVtxt: {
                fontFamily: Font.Poppins,
            },
            uploadIconWrapper: {
                backgroundColor: Color.LightAsh,
                width: "9.66vw",
                height: "9.66vw",
                borderRadius: "50%",
                margin: "auto auto 1.11vh auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            },
            uploadIcon: {
                width: "3.14vw",
                height: "auto"
            },
            greenTick: {
                position: "absolute",
                top: "-1vh",
                right: "-1.2vw",
                width: "4.50vw",
                height: "4.50vw",
                backgroundColor: Color.White,
                borderRadius: "50%"
            },
            removeCV: {
                position: "absolute",
                top: "2.41vw",
                left: "2.41vw",
                width: "3.86vw",
                height: "3.86vw",
                borderRadius: "50%",
                cursor: "pointer"
            },
            cvDialog: {
                popup: {
                    justifyContent: "center",
                    container: {
                        width: "90.33vw",
                        height: "166.67vw",
                        borderRadius: "2.41vw",
                        padding: "2.41vw"
                    },
                    closeButton: {
                        width: "3.86vw",
                        height: "3.86vw",
                        top: "1.93vw",
                        right: "1.93vw"
                    }
                },
                document: {
                    height: "100%"
                }
            },
            dialog: {            
                container: {                
                    width: "70vw",
                    height: "auto",
                    maxHeight: "61.8vw",
                    padding: "7.48vw  14.5vw",
                    borderRadius: "3vw 3vw 0 0"
                },
                closeButton: {
                    width: "4.58vw",
                    height: "4.58vw",
                    top: "3.41vw",
                    right: "5.70vw"
                }                      
            },
            dialogTitle: {
                margin: 0,
                fontSize: "7.24vw",
                marginLeft: "-8vw",
                color: Color.LightBlack
            },
            dialogMessage: {
                margin: "4.83vw 0 0 -8vw",
                fontSize: "3.86vw",
                color: Color.MiddleBlack,
                lineHeight: "5vw",
                fontWeight: 500
            },
            closeButton: {
                background: "transparent",
                border: "none",
                height: "12vw",            
                fontWeight: "bold" as "bold",
                color: Color.SharpAsh,
                fontSize: "4vw",
                float: "left" as "left",
                marginTop: "7vw",
                marginLeft: "-8vw",
                padding: 0
            },
            redirectButton: {
                backgroundColor: Color.Green,            
                borderRadius: "2.41vw",
                color: Color.White,
                height: "12vw",
                width: "43.71vw",
                border: "none",            
                fontSize: "3vw",            
                fontWeight: "bold" as "bold",
                float: "right" as "right",
                marginTop: "7vw",
                marginRight: "-8.5vw"
            }
        },
        questionForm: {
            applicationStage: {                
                fontWeight: "bold" as "bold",
                color: Color.LightBlack,
                fontSize: "6.03vw",
                margin: "7.24vw 0 0 0",
                letterSpacing: "0"
            },
            container: {
                display: "flex",
                marginBottom: "10vw"
            },
            stageDescription: {
                fontSize: "3.38vw",
                letterSpacing: "0.01vw",
                marginTop: "7.24vw",
                color: Color.MiddleBlack,
                width: "100%",
                textAlign: "left" as "left",
                lineHeight: "5vw",
                fontWeight: 400
            },
            guidelines: {
                title: {                    
                    fontWeight: "bold" as "bold",
                    color: Color.LightBlack,
                    fontSize: "6.03vw",
                    width: "64.49vw",
                    marginLeft: "4.83vw",
                    lineHeight: "8vw",
                    marginTop: "14vw"
                },
                content: {                    
                    color: Color.MiddleBlack,
                    fontSize: "3.38vw",
                    lineHeight: "5vw",
                    margin: "5.31vw 4.83vw 0 4.83vw"
                },
                list: {
                    listStyle: "none",
                    padding: "0"
                },
                listItem: {
                    display: "flex",
                    alignItem: "center",
                    marginTop: "2.89vw"
                },
                bullet: {
                    flexShrink: "0",
                    width: "2.41vw",
                    height: "2.41vw",
                    backgroundColor: Color.Blue,
                    borderRadius: "50%",
                    marginRight: "3.62vw",
                    marginTop: "1.8vw"
                },
                dialog: {            
                    container: {                
                        height: "auto"
                    },
                    closeButton: {
                        width: "4.58vw",
                        height: "4.58vw",
                        top: "3.41vw",
                        right: "5.70vw"
                    }                      
                }                
            },
            guidlinesNotice: {
                border: "1px solid " + Color.HarvestGold,
                backgroundColor: Color.LightGold,
                color: Color.HarvestGold,
                paddingRight: "3.38vw",
                borderRadius: "2.41vw",
                width: "87vw",
                height: "21.73vw",
                marginTop: "13.28vw",
                lineHeight: "6vw",
                marginLeft: "auto",
                fontSize: "3.86vw",
                cursor: "pointer", 
                text: {
                    marginLeft: "4.83vw",
                    marginRight: "3.38vw",
                    fontFamily: Font.Poppins,
                    fontWeight: 500,
                    width: "95%"
                },
                desktop: {
                    display: "none"
                },
                mobile: {
                    display: "block"
                }                 
            },
            questionWrapper: {
                border: "1px solid " + Color.LightGray,
                backgroundColor: Color.White,
                borderRadius: "10px",
                padding: "2.2vw",
                marginTop: "4vw",
                question: {
                    width: "80%"
                }
            },
            question: {
                display: "flex",
                flexDirection: "row",
                width: "100%",
                maxWidth: "78.26vw",                
                color: Color.DeepAsh,
                fontSize: "3.86vw",
                margin: "0 0 3.28vw 1vw",
                index: {
                    width: "fit-content",
                    fontFamily: Font.Poppins,
                    fontWeight: "Bold"   
                },
                text: {
                    marginLeft: "0.48vw",
                    fontFamily: Font.Poppins,
                    width: "100%"
                }
            },
            notAnswered: {     
                backgroundColor: Color.CoralRed,
                border: "1px solid" + Color.Red
            },
            switchContainer: {
                marginRight: "82%",
                marginBottom: "1.5vw"
            },
            switchInside: {
                switch: {
                    backgroundColor: Color.Ash,
                    width: "9.17vw", 
                    height: "5vw",
                    padding: "0.7vw 0.7vw",
                    borderRadius: "10vw"
                },
                switchCircle: {
                    height: "4.5vw",
                    width: "4.5vw",
                    marginTop: "0.2vw"
                }
            },
            changeDirection: {
                checkboxWrapper: {
                    width: "100%"
                },
                wrapper: {
                    width: "100%"
                },
            },
            selectAnswer: {
                checkboxWrapper: {
                    display: "inline-flex",
                    width: "auto",
                    minWidth: "47%",
                    maxWidth: "100%"
                },
                wrapper: {
                    display: "inline-flex",
                    width: "auto",
                    minWidth: "48%",
                    maxWidth: "100%"
                },
                customCheckBoxContainer: {
                    paddingLeft: "12vw"
                },
                checkbox: {
                    width: "4vw",
                    height: "4vw",
                    margin: "0.8vw 2.41vw 0 5.2vw"
                },
                radioInput: {
                    margin: "0 2.41vw 3.5vw 5.2vw",
                    width: "3.83vw",
                    height: "3.83vw",
                    boxShadow: "0 0 0 2px " + Color.Gainsboro,
                    border: "solid 3px " + Color.White
                },     
                label: {
                    fontSize: "3.86vw",
                    color: Color.DeepAsh,
                    cursor: "pointer",
                    fontFamily: Font.Poppins,
                    lineHeight: "4vw",
                    marginTop: "0"
                },
                checkboxLabel: {
                    fontSize: "3.86vw",
                    color: Color.DeepAsh,
                    fontFamily: Font.Poppins
                }
            },
            inputField: {
                extendArea: {
                    backgroundColor: Color.LightAsh,
                    border: "1px solid transparent",
                    width: "86%",
                    marginBottom: "2vw",
                    marginLeft: "5vw",
                    display: "block",
                    overflow: "hidden",
                    resize: "none",
                    minHeight: "6vw",
                    lineHeight: "5vw",
                    outline: "none",
                    borderRadius: "1.20vw",
                    fontSize: "16px",
                    padding: "3vw",
                    cursor: "pointer"
                }
            }
        },
        preApplyForm: {
            applyStage: {                
                fontWeight: "bold" as "bold",
                color: Color.LightBlack,
                fontSize: "6.03vw",
                margin: "5.58vh 0 0 0",
                letterSpacing: "0px"
            },
            stageDescription: {
                fontSize: "3.38vw",
                letterSpacing: "0px",
                fontWeight: "500",
                color: Color.MiddleBlack,
                lineHeight: "6.03vw",
                width: "95%",
                textAlign: "left" as "left",
                margin: "7.24vw 0 12vw 0"
            },
            questionWrapper: {
                position: "relative" as "relative",
                border: "unset"
            },
            longQuestionWrapper: {
                width: "100%",
                paddingBottom: "9.66vw",
                borderBottom: "0.11vw solid" + Color.Gainsboro
            },
            question: {                
                color: Color.DeepAsh,
                fontSize: "3.864vw",
                fontWeight: 450,
                margin: "4.83vw 0 -2vw 3.62vw",
                fontFamily: Font.Poppins
            },
            answerWrapper: {
                position: "relative",
                display: "flex",
                height: "12vw",
                alignItems: "center",
                padding: "3.62vw",
                marginTop: "4vw",                
                backgroundColor: Color.LightAsh,
                border: "none",
                borderRadius: "1.20vw",
                boxSizing: "border-box",
                transition: "all .2s ease-in-out",
                WebkitTapHighlightColor : "transparent",
                cursor: "Pointer",
                justifyContent: "space-between",
                hover: {
                    border: "1px solid " + Color.SharpAsh,
                    borderRadius: "1.93vw 1.93vw 0 0",
                    backgroundColor: Color.White
                }
            },
            valid: {
                backgroundColor: Color.LightBlue,
                border: "1px solid " + Color.Blue
            },
            invalid: {
                backgroundColor: Color.CoralRed,
                border: "1px solid " + Color.Red,
                color: Color.Red
            },              
            leave: {
                display: "none"            
            },
            textInput: {
                height: "12vw"
            },
            hover: {
                backgroundColor: Color.White,
                width: "85vw",
                boxShadow: "none",
                border: "1px solid " + Color.LightSilver,
                borderTop: "none",
                borderRadius: "0 0 1.93vw 1.93vw",
                marginTop: "0",
                zIndex: 2,                    
                padding: "2.415vw",
                position: "absolute" as "absolute"
            },
            option: {
                padding: "0.44vh 2.415vw",
                WebkitTapHighlightColor : "transparent",
                fontFamily: Font.Poppins,
                color: Color.MiddleBlack,
                fontSize: "3.864vw",
                fontWeight: 500,
                hover: {
                    backgroundColor: Color.Ash,
                    borderRadius: "1.69vw",
                    cursor: "pointer"
                }
            },
            datePicker: {
                padding: "0.44vh 2.415vw",
                WebkitTapHighlightColor : "transparent",
                fontFamily: Font.Poppins,
                color: Color.SharpAsh,
                fontSize: "3.864vw",
                border: "1px solid"+Color.MiddleAsh,
                borderRadius: "2.41vw",
                lineHeight: "10vw",
                marginBottom: "2.41vw",
                paddingLeft: "4.83vw",
                hover: {
                    backgroundColor: Color.Blue,
                    color: Color.White,
                    borderRadius: "1.20vw",
                    cursor: "pointer"
                }
            },
            tickMark: {
                float: "right",
                marginTop: "2.5vw",
                marginRight: "4.83vw",
                fill: Color.White,
                height: "4.83vw",
                width: "4.83vw"
            },
            bottomBorder: {
                display: "block",
                border: 0,
                height: "1px",
                width: "100%",
                background: Color.Ash,
                margin: "0 auto",
                zIndex: "1",
                hide: {
                    display: "none",
                }               
            },
            text: {
                fontSize: "3.864vw",
                fontFamily: Font.Poppins,
                color: Color.SharpAsh
            },
            icon: {
                width: "4.10vw",
                height: "4.10vw",
                marginLeft: "auto",
                marginRight: "0",
                transition: "transform .1s ease-in-out"
            },
            rotateIcon: {
                transform: "rotate(180deg)"
            },
            inputError: {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                fontSize: "4.44vw",
                textAlign: "center",
                icon: {
                    width: "4.10vw",
                    height: "4.10vw",
                    marginLeft: "auto"
                }
            }
        },
        applyComplete: {
            container: {
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "-10.50vw",
                marginBottom: "11.16vh",
                textAlign: "center" as "center"
            },
            image: {
                width: "73.91vw",
                marginLeft: "4vw"
            },
            title: {                
                fontWeight: "bold" as "bold",
                fontSize: "6.03vw",
                color: Color.LightBlack,
                margin: "3.34vh auto 3.34vh auto",
                width: "61.35vw",
                maxWidth: "58vw"
            },
            description: {
                color: Color.MiddleBlack,
                fontSize: "3.38vw",
                width: "90.33vw",
                margin: "-2vw auto 3.34vh auto",
                lineHeight: "5vw"
            },
            buttonsContainer: {
                display: "block",
                justifyContent: "center",
                marginTop: "-3vw"
            },
            button: {
                border: "none",
                backgroundColor: Color.Green,
                color: Color.White,
                borderRadius: "2.41vw",
                width: "90.33vw",
                height: "5.58vh",
                padding: "1.67vh",
                fontWeight: "500",
                fontSize: "4.34vw",
                marginTop: "1.52vh",
                boxShadow: "5px 5px 50px #00000027",
                back: {
                    backgroundColor: Color.Blue,
                    marginLeft: "0",
                    boxShadow: "none"
                }
            }
        },
        dialog: {   
            justifyContent: "center",         
            container: {                
                width: "78%",
                height: "auto",
                maxHeight: "61.8vw",
                padding: "7.48vw",
                borderRadius: "3vw"
            },
            closeButton: {
                width: "4.58vw",
                height: "4.58vw",
                top: "3.41vw",
                right: "5.70vw"
            },
            message: {
                margin: "0",
                fontSize: "3.86vw",
                color: Color.MiddleBlack,
                lineHeight: "5vw",
                fontWeight: 500
            },
            close: {
                background: "transparent",
                border: "none",
                height: "12vw",            
                fontWeight: "bold" as "bold",
                color: Color.SharpAsh,
                fontSize: "4vw",
                float: "left" as "left",
                marginTop: "7vw",
                marginLeft: 0,
                padding: 0
            },
            redirect: {
                backgroundColor: Color.Green,            
                borderRadius: "2.41vw",
                color: Color.White,
                height: "12vw",
                width: "43.71vw",
                border: "none",            
                fontSize: "3vw",            
                fontWeight: "bold" as "bold",
                float: "right" as "right",
                marginTop: "7vw",
                marginRight: 0
            }                     
        }        
    },
    desktop: {
        mainWrapper: {
            display: "flex",
            flexDirection: "row-reverse",
            margin: "8vw 7.29vw 12.03vh 7.29vw"
        },
        leftWrapper: {
            paddingRight: "2.88vw",
            paddingTop: "2vw",
        },
        reducePadding: {
            paddingTop: "0",
        },
        rightWrapper: {
            width: "30%",
            borderLeft: "2px solid " + Color.MiddleAsh,
            paddingLeft: "2.88vw",
            paddingTop: "1.8vw"
        },
        applyStatus: {
            borderRadius: "0.52vw",
            height: "6.61vw",
            marginTop: "-10.5vw",
            width: "40.625vw",
            applyComplete:{
                marginTop: "-10.5vw"
            },
            box: {
                borderRadius: "0.52vw",
                width: "4vw",
                height: "4vw",
                margin: "0.52vw"
            },
            boxHolder: {
                width: "5.052vw",
                height: "5.052vw",
                borderRadius: "0.52vw",
                top: "1.38vh",
                bottom: "1.38vh",
                applying: {
                    left: "0.78vw"
                },
                applied: {
                    right: "0.78vw"
                }
            },
            icon: {
                width: "1.3vw",
                height: "1.3vw",
                apply: {
                    width: "1.09vw",
                    height: "1.35vw"
                },
                screening: {
                    width: "1.30vw",
                    height: "1.87vw"
                },
                applied: {
                    width: "1.97vw",
                    height: "1.87vw"
                }         
            },
            progressBar: {
                top: "2.9vw",
                height: "2px"
            },
            cvLess: {
                width: "23.59vw",
                marginTop: "-10.8vw"
            },
            filteringBox: {
                width: "23.59vw"
            }
        },
        verticalJobDetailCard: {
            height: "auto",
            boxShadow: "unset",
            marginTop: "0",
            zIndex: "unset",
            marginLeft: "0",
            hideSummary: {
                height: "auto",
                line: {
                    display: "block"
                },
                summary: {
                    display: "block"
                }
            },
            line: {
                display: "block"
            },
            summary: {
                marginTop: "0",
                marginBottom: "-1vw",
                display: "block",
                item: {
                    marginLeft: "0.1vw",
                    disply: "block"
                },
                key: {
                    marginRight: "0",
                },
                value: {
                    paddingLeft: "1vw"            
                }
            },
            overview: {
                display: "block"
            },
        },
        organizationCard: {
            display: "block"
        },
        applyForm: {
            inputGroup: {
                display: "flex"
            },
            label: {
               fontSize: "0.93vw",
               marginLeft: "0.52vw",
               leftSpaced: {
                marginLeft: "1.5vw"
               }
            },   
            inputField: {
                width: "27.91vw",
                marginTop: "0.52vw",
                marginLeft: "0",
                marginBottom: "0",
                leftSpaced: {
                    marginLeft: "0.83vw"
                }
            },
            viewCV: {
                fontSize: "0.70vw",
                fontWeight: "0"
            },
            cvHolder: {
                width: "50%",
                marginTop: "1.30vw",
                marginBottom: "1.10vw"
            },
            resumeSectionTexts: {
                width: "50%",
                fontSize: "0.93vw",
                marginTop: "3.61vh"
            },
            stageDescription: {
                fontSize: "0.83vw",                
                width: "43.85vw",
                margin: "2.5vh 0 2.2vw 0",
                lineHeight: "1.3vw",
                maxWidth: "73%"
            },
            applyStage: {
                fontSize: "1.56vw",
                margin: "0"
            },
            cvDescription: {
                margin: "1.38vh 0 2.77vh 0",
                fontSize: "0.83vw",
                width: "26vw",
                lineHeight: "1.2vw"
            },
            coverLetterEditor: {
                minHeight: "22.76vw",
                width: "56.67vw",
                border: "0.05vw solid" + Color.MiddleAsh
            },
            documentViewer: {                
                borderRadius: "0.52vw"
            },            
            viewCVIcon: {
                marginLeft: "0.45vw",
                marginBottom: "0vw",
                width: "0.6vw",
                height: "0.6vw"
            },
            existingCvLabel: {
                bottom: "6%",
                left: "6%",
            },
            existingCvName: {
                fontSize: "0.937vw",
                whiteSpace: "nowrap",
                maxWidth: "12vw",
                overflow: "hidden"
            },
            uploadedCVText: {
                display: "block",
                fontFamily: Font.Poppins,
                fontSize: "0.83vw"
            },
            uploadedCV: {
                display: "block",
                fontFamily: Font.Poppins,
                fontSize: "0.83vw",
                marginTop: "0.16vw"
            },
            uploadCV: {
                width: "13.54vw",
                height: "17.44vw",
                borderRadius: "0.52vw",
                marginLeft: "0.83vw"
            },
            existingCV: {
                width: "13.54vw",
                height: "17.44vw",
                borderRadius: "0.52vw"
            },
            existingCvLayer: {                
                borderRadius: "0.52vw"
            },
            uploadCVTexts: {
                fontSize: "0.93vw",
                width: "8.43vw",
            },
            uploadCVtxt: {
                lineHeight: "1.30vw"
            },
            uploadCVMaxFileSize: {
                fontSize: "0.729vw",
                margin: "-0.8vw 0 0 0"
            },
            uploadIconWrapper: {
                width: "2.60vw",
                height: "2.60vw",
                margin: "auto auto 1.38vh auto"
            },
            uploadIcon: {
                width: "0.83vw"
            },
            greenTick: {
                width: "1.04vw",
                height: "1.04vw",
                top: "-0.52vw",
                right: "-0.50vw",
            },
            removeCV: {
                top: "0.52vw",
                left: "0.52vw",
                width: "0.83vw",
                height: "0.83vw"
            },
            coverLetterLabel: {
                fontSize: "0.93vw",
                margin: "1.5vw 0 0.92vh 0.52vw"           
            },
            cvDialog: {
                popup: {
                    container: {                    
                        width: "30.99vw",
                        height: "43.85vw",                    
                        borderRadius: "0.52vw",
                        padding: "0.83vw"
                    },
                    closeButton: {                    
                        width: "0.94vw",
                        height: "0.94vw",
                        top: "0.21vw",
                        right: "0.21vw"
                    }
                }
            },
            dialog: {            
                container: {                
                    width: "23.33vw",
                    height: "auto",
                    maxHeight: "316.61vw",
                    padding: "3.48vw 3.12vw",
                    borderRadius: "0.52vw"
                },
                closeButton: {
                    fill: Color.Gainsboro,
                    stroke: Color.Gainsboro,
                    strokeWidth: 4,
                    width: "0.59vw",
                    height: "0.59vw",
                    top: "0.90vw",
                    right: "0.90vw"
                }   
            },
            dialogTitle: {
                margin: 0,
                fontSize: "1.56vw",
                marginLeft: "0",
                color: Color.LightBlack
            },
            dialogMessage: {
                margin: "1.51vw 0 0 0",
                fontSize: "0.83vw",
                color: Color.MiddleBlack,
                lineHeight: "1.2vw",
                fontWeight: "500"
            },
            closeButton: {
                background: "transparent",
                border: "none",
                height: "2.60vw",            
                fontWeight: "bold" as "bold",
                color: Color.SharpAsh,
                fontSize: "0.93vw",
                float: "left" as "left",
                marginTop: "1.51vw",
                padding: 0,
                marginLeft: "0"
            },
            redirectButton: {
                backgroundColor: Color.Green,            
                borderRadius: "0.52vw",
                color: Color.White,
                height: "2.60vw",
                width: "12.97vw",
                border: "none",            
                fontSize: "1.04vw",            
                fontWeight: "bold" as "bold",
                float: "right" as "right",
                marginTop: "1.51vw",
                marginRight: "0"
            }
        },
        questionForm: {
            container: {
                marginTop: "3.40vw",
                marginBottom: "2vw",
                alignItems: "center"
            },
            stageDescription: {
                fontSize: "0.83vw",
                width: "32.86vw",
                margin: "-2.5vw 3.07vw 0 0",
                letterSpacing: "0.01vw",
                lineHeight: "1.4vw"
            },
            guidelines: {
                title: {                    
                    fontSize: "1.56vw",
                    width: "22.39vw",
                    marginLeft: "4.16vw",
                    lineHeight: "8vw",
                    marginTop: "0"
                },
                content: {                    
                    fontSize: "0.83vw",
                    lineHeight: "1.3vw",
                    margin: "-3.40vw 2.3vw 0 4.16vw"
                },
                listItem: {
                    marginTop: "1.04vw"
                },
                bullet: {
                    flexShrink: "0",
                    width: "0.52vw",
                    height: "0.52vw",
                    borderRadius: "50%",
                    marginRight: "0.78vw",
                    marginTop: "0.4vw"
                },
                dialog: {            
                    container: {                
                        width: "47.70vw",
                        height: "38.75vw",
                    },
                    closeButton: {
                        width: "1.35vw",
                        height: "1.35vw",
                        top: "0.90vw",
                        right: "0.90vw"
                    }                      
                },
            },
            guidlinesNotice: {
                paddingLeft: "1.04vw",
                paddingRight: "0vw",
                borderRadius: "0.52vw",
                width: "20.46vw",
                height: "4.68vw",
                marginLeft: "auto",
                marginTop: "-2.4vw",
                fontSize: "0.937vw",
                lineHeight: "1.3vw",
                text: {
                    marginLeft: "0",
                    marginRight: "0"
                },
                desktop: {
                    display: "block"
                },
                mobile: {
                    display: "none"
                }               
            },
            questionWrapper: {
              padding: "1.04vw 1.30vw",
              marginTop: "1.04vw",
              borderRadius: "0.52vw",
              yesNo: {
                display: "flex"
              }
            },
            question: {
                fontSize: "0.937vw",                
                margin: "0 0 0.93vw 0",
                text: {
                    marginLeft: "0.26vw",
                    maxWidth: "90%",
                    fontFamily: Font.Poppins
                }
            },
            switchContainer: {
                marginRight: "0%",
                marginTop: "1%",
                marginBottom: "1%"
            },
            switchInside: {
                switch: {
                    width: "2.7vw", 
                    height: "1.50vw",
                    padding: "0.20vw"
                },
                switchCircle: {
                    width: "1.2vw", 
                    height: "1.2vw",
                    padding: "0.10vw 0.10vw",
                    borderRadius: "0.78vw",
                    marginTop: "unset"
                }
            },
            applicationStage: {
                fontSize: "1.562vw",
                marginTop: "-2.5vw"
            },
            changeDirection: {
                checkboxWrapper: {
                    width: "100%",
                    marginRight: "1.56vw"
                },
                wrapper: {
                    width: "100%",
                    marginRight: "1.56vw"
                },
            },
            selectAnswer: {
                checkboxWrapper: {
                    width: "auto",
                    minWidth: "5%",
                    maxWidth: "100%",
                    marginRight: "1.56vw"
                },
                wrapper: {
                    width: "auto",
                    minWidth: "5%",
                    maxWidth: "100%",
                    marginRight: "1.56vw"
                },
                customCheckBoxContainer: {
                    paddingLeft: "2.5vw"
                },
                checkbox: {
                    height: "0.8vw",
                    width: "0.8vw",
                    margin: "0.3vw 0.52vw 0 1.14vw"
                },
                radioInput: {
                    margin: "0 0.52vw 0vw 1.14vw",
                    border: "solid 3px "+ Color.White,
                    width: "0.8vw",
                    height: "0.8vw"
                },
                label: {
                    marginTop: "-0.3vw",
                    fontSize: "0.937vw",
                    lineHeight: "1.6vw"
                },
                checkboxLabel: {
                    fontSize: "0.937vw",
                    lineHeight: "1.7vw"
                }
            },
            inputField: {
                extendArea: {
                    width: "52.65vw",
                    marginBottom: "0",
                    marginLeft: "1.1vw",
                    minHeight: "0",
                    lineHeight: "1vw",
                    borderRadius: "0.52vw",
                    fontSize: "0.83vw",
                    padding: "1.2vw"
                }
            }
        },        
        preApplyForm: {            
            applyStage: {
                fontSize: "1.56vw",
                margin: "0"
            },
            stageDescription: {
                fontSize: "0.83vw",
                fontWeight: "400",
                width: "80%",
                margin: "1.40vw 3.07vw 2.7vw 0",
                lineHeight: "1.30vw"
            },
            question: {
                fontSize: "0.937vw",
                fontWeight: 400,
                margin: "1.56vw 0 0.52vw 0.52vw"
            },
            questionWrapper: {
                display: "inline-block",                           
                width: "27.91vw",
                marginRight: "0.83vw"
            },
            longQuestionWrapper: {
                paddingBottom: "2.08vw",
                width: "56.66vw"
            },
            answerWrapper: {
                borderRadius: "0.52vw",
                height: "8vh",
                marginTop: "0",
                padding: "0.7vw",
                hover: {
                    borderRadius: "0.52vw 0.52vw 0 0"
                }
            },
            hover: {
                width: "26.77vw",
                padding: "0.52vw",
                borderRadius: "0 0 0.52vw 0.52vw",
                maxHeight: "28vh"
            },
            textInput: {
                height: "3.64vw"
            },
            option: {
                padding: "0.37vh 0.52vw",
                fontSize: "0.83vw",
                lineHeight: "2.77vh",
                hover: {
                    borderRadius: "0.36vw"
                }
            },
            datePicker: {
                paddingLeft: "1.56vw",
                fontSize: "0.93vw",
                lineHeight: "2.5vw",
                borderRadius: "0.52vw",
                marginBottom: "0.52vw",
                hover: {
                    borderRadius: "0.26vw"
                }
            },
            tickMark: {
                float: "right",
                marginTop: "0.65vw",
                marginRight: "-1vw",
                height: "1.04vw",
                width: "1.04vw"
            },
            icon: {
                width: "0.88vw",
                height: "0.88vw"
            },
            text: {
                fontSize: "0.93vw",
                marginLeft: "1vw"
            },
            inputError: {
                fontSize: "1vw",
                icon: {
                    width: "1.05vw",
                    height: "1.05vw"
                }
            },
            submitArea: {
                buttonsContainer: {
                    display: "flex",
                    width: "53%",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "left"
                },
                backButton: {
                    display: "none"
                },
                cancel: {
                    display:  "block",
                    fontSize: "0.93vw",
                    marginTop: "0",
                    marginLeft: "2.5vw"
                }
            }            
        },
        applyComplete: {
            container: {
                width: "43.85vw",
                marginBottom: "1vw",
                marginTop: "-2.32vw"
            },
            title: {
                fontSize: "1.56vw",
                width: "unset",
                margin: "0.6vw 0 1.19vw 0",
                maxWidth: "none"
            },
            image: {                
                width: "20vw",
                marginLeft: "1.3vw"
            },
            description: {                
                fontSize: "0.83vw",
                lineHeight: "1.3vw",
                margin: "2.5vh 0 3.42vh 0",
                width: "unset"
            },
            buttonsContainer: {
               marginTop: "-1vw"
            },
            button: {
                width: "18.125vw",
                height: "7.8vh",
                fontSize: "1.04vw",
                padding: "2.12vh",
                borderRadius: "0.52vw",
                back: {
                    marginLeft: "0.78vw"
                }
            }
        },
        dialog: {            
            container: {                
                width: "50vw",
                height: "16vw",
                maxHeight: "316.61vw",
                padding: "2.48vw 2.12vw",
                borderRadius: "0.52vw"
            },
            closeButton: {
                fill: Color.Gainsboro,
                stroke: Color.Gainsboro,
                strokeWidth: 4,
                width: "0.59vw",
                height: "0.59vw",
                top: "0.90vw",
                right: "0.90vw"
            },
            message: {
                margin: "1vw 1vw 6vw",
                fontSize: "1.6vw",
                color: Color.MiddleBlack,
                lineHeight: "2vw",
                fontWeight: "500"
            },
            close: {
                background: "transparent",
                border: "none",
                height: "2.60vw",            
                fontWeight: "bold" as "bold",
                color: Color.SharpAsh,
                fontSize: "1.6vw",
                float: "left" as "left",
                marginTop: "0.5vw",
                padding: 0,
                marginLeft: "1vw"
            },
            redirect: {
                backgroundColor: Color.Green,            
                borderRadius: "0.52vw",
                color: Color.White,
                height: "4.20vw",
                width: "20vw",
                border: "none",            
                fontSize: "1.80vw",            
                fontWeight: "bold" as "bold",
                float: "right" as "right",
                marginTop: "0",
                marginRight: "1vw"
            } 
        }
    }
}