import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const quizPage = {
    mobile: {
        container: {
            position: "relative",
            zIndex: 1
        },
        valid: {
            margin: "4vh 5% 8vh 5%",
            title: {
                fontSize: "2.4vh",
                color: Color.DeepAsh,
                textAlign: "center"
            },
            list: {
                listStyleType: "none",
                color: Color.DeepAsh,
                paddingLeft: "2.2vw"
            },
            item: {
                fontSize: "2vh",
                padding: "0.4vh"
            },
            bold: {
                fontWeight: "bold"
            }
        },
        questions: {
            wrapper: {
                width: "90%",
                margin: "1.85vh auto 2.85vh auto"
            },
            infoWrapper: {
                marginBottom: "4.6vh"
            },
            rowWrapper: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: "1.6vh"
            },
            info: {
                fontStyle: "italic",
                color: Color.DeepAsh,
                fontSize: "3.86vw"
            },
            countdown: {
                fontWeight: "bold",
                fontSize: "2vh",
                color: Color.Blue
            },
            questionWrapper: {
                border: "1px solid " + Color.LightGray,
                backgroundColor: Color.White,
                borderRadius: "10px",
                padding: "8px",
                margin: "3.62vw auto 0 auto"             
            },
            question: {
                display: "flex",
                width: "100%",                
                color: Color.DeepAsh,
                fontFamily: Font.Poppins,
                fontSize: "3.86vw",
                lineHeight: "6.04vw",
                margin: "0 0 1.67vh 0"
            },
            selectAnswer: {
                wrapper: {
                    display: "inline-flex",
                    width: "50%"
                },
                radioInput: {
                    margin: "0 2.41vw 2.66vw 3.50vw",
                    width: "4.83vw",
                    height: "4.83vw"
                },
                label: {
                    fontSize: "3.86vw",
                    color: Color.DeepAsh,
                    cursor: "pointer"
                }
            },
            bold: {
                fontWeight: "bold"
            },
            button: {
                border: "none",
                backgroundColor: Color.Blue,
                color: Color.White,
                borderRadius: "2.41vw",
                width: "100%",
                height: "5.58vh",
                fontSize: "2vh",
                fontWeight: "bold" as "bold",
                marginTop: "2vh",
                submit: {
                    backgroundColor: Color.Green
                }
            }
        },
        interview: {
            form: {
                width: "90%",
                margin: "4vh auto 11.16vh auto",
                textAlign: "center" as "center"
            },
            headerTitle: {
                fontSize: "4.25vw"
            },
            question: {                
                color: Color.DeepAsh,
                fontSize: "3.864vw",
                margin: "2.23vh 0 0.55vh 3.63vw",
                textAlign: "left" as "left"
            },            
            answerWrapper: {
                position: "relative",
                display: "flex",
                height: "6.76vh",
                alignItems: "center",
                padding: "3.62vw",
                margin: "auto",
                backgroundColor: Color.LightAsh,
                border: "none",
                borderRadius: "1.93vw",
                boxSizing: "border-box",
                transition: "all .2s ease-in-out",
                WebkitTapHighlightColor : "transparent",
                hover: {
                    border: "1px solid " + Color.SharpAsh,
                    borderRadius: "1.93vw 1.93vw 0 0",
                    backgroundColor: Color.White
                }                    
            },                
            answer: {
                height: "100%"
            },
            leave: {
                display: "none"            
            },
            hover: {
                backgroundColor: Color.White,
                width: "90%",
                boxShadow: "none",
                border: "1px solid " + Color.LightSilver,
                borderTop: "none",
                borderRadius: "0 0 1.93vw 1.93vw",
                marginTop: "0",
                zIndex: 2,                    
                padding: "2.415vw 0",
                position: "absolute" as "absolute"
            },
            datePicker: {
                padding: "0.44vh 2.415vw",
                WebkitTapHighlightColor : "transparent",
                fontFamily: Font.Poppins,
                color: Color.SharpAsh,
                fontSize: "3.864vw",
                border: "1px solid"+Color.MiddleAsh,
                borderRadius: "2.41vw",
                lineHeight: "10vw",
                marginBottom: "2.41vw",
                paddingLeft: "4.83vw",
                hover: {
                    backgroundColor: Color.Blue,
                    color: Color.White,
                    borderRadius: "1.20vw",
                    cursor: "pointer"
                }
            },
            tickMark: {
                float: "right",
                marginTop: "2.5vw",
                marginRight: "4.83vw",
                marginLeft: "auto",
                fill: Color.White,
                height: "4.83vw",
                width: "4.83vw"
            },
            answerText: {
                fontSize: "3.864vw",
                color: Color.SharpAsh
            },
            answerIcon: {
                width: "4.10vw",
                height: "4.10vw",
                marginLeft: "auto",
                marginRight: "0",
                transition: "transform .1s ease-in-out"
            },
            rotateIcon: {
                transform: "rotate(180deg)"
            },
            button: {
                border: "none",
                backgroundColor: Color.Green,
                color: Color.White,
                borderRadius: "2.41vw",
                width: "90.33vw",
                height: "5.58vh",
                padding: "1.67vh",
                fontWeight: "bold" as "bold",
                marginTop: "1.52vh"
            }
        },
        complete: {
            container: {
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "-4.83vh",
                marginBottom: "11.16vh",
                textAlign: "center" as "center"
            },
            image: {
                width: "73vw"                
            },
            title: {                
                fontWeight: "bold" as "bold",
                fontSize: "6.03vw",
                color: Color.LightBlack,
                margin: "3vh auto",
                width: "61.35vw"
            },
            description: {
                color: Color.MiddleBlack,
                fontSize: "4vw",
                width: "90.33vw",
                margin: "0 auto 3.34vh auto"
            },
            link: {                
                color: Color.Blue,
                textDecoration: "underline",
                cursor: "pointer"
            },
            buttonsContainer: {
                display: "block",
                justifyContent: "center"
            },
            button: {
                border: "none",
                backgroundColor: Color.Green,
                color: Color.White,
                borderRadius: "2.41vw",
                width: "90.33vw",
                height: "5.58vh",
                padding: "1.67vh",
                fontWeight: "bold" as "bold",
                marginTop: "1.52vh",
                back: {
                    backgroundColor: Color.Blue,
                    marginLeft: "0"
                }
            }
        },
        button: {
            border: "none",
            backgroundColor: Color.Green,
            color: Color.White,
            borderRadius: "2.41vw",
            width: "100%",
            height: "5.58vh",
            fontSize: "2vh",
            fontWeight: "bold" as "bold",
            marginTop: "2vh"
        }
    },
    desktop: {
        valid: {
            width: "fit-content",
            margin: "6vh auto 10vh auto",
            title: {
                fontSize: "3.4vh"
            },
            list: {
                paddingLeft: "7.20vw"
            },
            item: {
                fontSize: "2.6vh",
                padding: "0.8vh"
            },
            bold: {
                fontWeight: "bold"
            }
        },
        questions: {
            wrapper: {
                width: "70%",
                margin: "4vh auto"
            },
            infoWrapper: {
                marginBottom: "4vh"
            },
            rowWrapper: {
                marginBottom: "1.6vh"
            },
            info: {
                fontSize: "0.937vw"
            },
            countdown: {
                fontWeight: "bold",
                fontSize: "2vh",
                color: Color.Blue
            },
            line: {
                height: "1px",
                color: Color.MiddleAsh,
                backgroundColor: Color.MiddleAsh,
                border: "none"
            },
            questionWrapper: {
                padding: "1.85vh 1.30vw",
                marginTop: "1.85vh",
                borderRadius: "0.52vw"
            },
            question: {
                lineHeight: "unset",
                width: "75%",
                fontSize: "0.937vw",                
                margin: "0 0 1.04vw 0"
            },
            selectAnswer: {
                wrapper: {
                    width: "unset",
                    height: "1.30vw",
                    marginRight: "1.56vw"
                },
                radioInput: {
                    marginRight: "0.52vw",
                    marginLeft: "1vw",
                    width: "1.04vw",
                    height: "1.04vw"
                },
                label: {
                    fontSize: "0.937vw"
                }
            },
            button: {
                display: "block",
                borderRadius: "0.52vw",
                width: "18.125vw",
                height: "5.58vh",
                fontSize: "1.04vw",
                marginTop: "5.2vh",                
                marginLeft: "auto"
            }
        },
        interview: {
            form: {
                width: "34.85vw",
                margin: "4vh auto 13.88vh auto"
            },
            headerTitle: {
                fontSize: "1.40vw"
            },
            question: {
                fontSize: "0.937vw",
                margin: "2.77vh 0 0.92vh 0.82vw"
            },            
            answerWrapper: {
                borderRadius: "0.52vw",
                height: "8vh",
                padding: "0.7vw",
                hover: {
                    borderRadius: "0.52vw 0.52vw 0 0"
                }
            },
            hover: {
                width: "34.85vw",
                padding: "0.52vw 0",
                borderRadius: "0 0 0.52vw 0.52vw"
            },
            datePicker: {
                paddingLeft: "1.56vw",
                fontSize: "0.93vw",
                lineHeight: "2.5vw",
                borderRadius: "0.52vw",
                marginBottom: "0.52vw",
                hover: {
                    borderRadius: "0.26vw"
                }
            },
            tickMark: {
                marginTop: "0.65vw",
                marginRight: "-1vw",
                height: "1.04vw",
                width: "1.04vw"
            },
            answerIcon: {
                width: "0.88vw",
                height: "0.88vw"
            },
            answerText: {
                fontSize: "0.93vw",
                margin: ""
            },
            button: {
                display: "block",
                borderRadius: "0.52vw",
                width: "18.125vw",
                height: "5.58vh",
                fontSize: "1.04vw",
                marginTop: "5.2vh",                
                marginLeft: "auto"
            }          
        },
        complete: {
            container: {
                width: "43.85vw",
                marginBottom: "13.88vh",
                marginTop: "0"
            },
            title: {
                fontSize: "2vw",
                width: "unset",
                margin: "2vh 0 2.5vh 0"
            },
            image: {                
                width: "20vw",
                height: "38vh"
            },
            description: {                
                fontSize: "1.4vw",
                lineHeight: "2.5vh",
                margin: "2.5vh 0 3.42vh 0",
                width: "unset"
            },
            button: {
                width: "18.125vw",
                height: "7.8vh",
                fontSize: "1.04vw",
                padding: "2.12vh",
                borderRadius: "0.52vw",
                back: {
                    marginLeft: "0.78vw"
                }
            }
        },
        button: {           
            width: "18.125vw",
            height: "7.8vh",
            fontSize: "1.04vw",
            padding: "2.12vh",
            borderRadius: "0.52vw",
            marginTop: "2vh",
            marginLeft: "21.05vw",
            back: {
                backgroundColor: Color.Blue,
                marginLeft: "0"
            }
        }
    }
}