import { Organization } from "../../models/Organization";
import { CommonTexts, JobViewTexts as Texts } from '../../helpers/LayoutTexts';
import { Tag } from "./Tag";
import { useContext, useEffect, useState } from "react";
import { StyleContext } from "../../providers/StyleContextProvider";
import { useMediaQuery } from "react-responsive";
import { merge } from "react-merge";
import { ReactComponent as Location } from '../../assets/svg/location.svg';
import { ReactComponent as Users } from '../../assets/svg/users.svg';
import { ReactComponent as Website } from '../../assets/svg/web_icon.svg';
import { ReactComponent as Facebook } from '../../assets/svg/facebook.svg';
import { ReactComponent as LinkedIn } from '../../assets/svg/linkedin.svg';
import { ReactComponent as Twitter } from '../../assets/svg/twitter.svg';
import { ReactComponent as Youtube } from '../../assets/svg/youtube.svg';
import { ReactComponent as Address } from '../../assets/svg/address.svg';
import { ReactComponent as DisableIcon } from '../../assets/svg/disable.svg';
import { ReactComponent as StartUpSlIcon } from '../../assets/svg/startup_sl.svg';
import { ReactComponent as RainbowIcon } from '../../assets/svg/rainbow_flag.svg';
import { useNavigate } from "react-router-dom";
import { getSEOKeyword } from "../../helpers/Common";
import { Button } from "./Buttons/Button";

interface Props {
    styles?: any;
    organization: Organization;
    buttonText: string;
    preventRedirection?: boolean;
}

export const JobOrganizationCard: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("jobOrganizationCard"));                                 
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const organization = props.organization;

    useEffect(() => {   
        // merge base styles with component specific styles                  
        let style = styleContext.getComponentStyle("jobOrganizationCard"); 
        let extendedStyles = merge(style, props.styles);
        setStyles(extendedStyles);                    
    }, [isMobile]);
    
    const handleLogoClick = (event: any) => {
        if(!props.preventRedirection){
            event.stopPropagation();
            navigate("/organization/" + organization.organizationId + "/" + getSEOKeyword(organization.organizationName));
        }
    }

    const handleWebUrlClick = (event: any) => {
        event.stopPropagation();
        window.open(organization.website, '_blank');
    }

    const handleFacebookUrlClick = (event: any) => {
        event.stopPropagation();
        window.open(organization.facebookUrl, '_blank');
    }

    const handleLinkedinUrlClick = (event: any) => {
        event.stopPropagation();
        window.open(organization.linkedInUrl, '_blank');
    }

    const handleTwitterUrlClick = (event: any) => {
        event.stopPropagation();
        window.open(organization.twitterUrl, '_blank');
    }

    const handleYoutubeUrlClick = (event: any) => {
        event.stopPropagation();
        window.open(organization.youtubeUrl, '_blank');
    }


    return (
        <div style={styles}>            
            <div style={styles.logoWrapper}>
                <img src={organization.hasLogo && organization.logoUri ? organization.logoUri : CommonTexts.defaultCompanyLogo}
                    style={styles.logo}
                    onClick={handleLogoClick} className={"responseive-square"}>
                </img>
                <div style={styles.columnWrapper}>
                    <div style={styles.name}>{organization.organizationName}</div>
                    <div style={styles.jobPostsTxt}><span style={styles.count}>{organization.liveJobCount}</span>{organization.liveJobCount > 1 ? Texts.jobPosts : Texts.jobPost}</div>
                </div>                              
            </div>
            <div style={styles.rowWrapper}>
                <div style={styles.tagWrapper}>
                    <Tag styles={styles.tag}>
                        <Location style={styles.tagIcon}/>
                        {organization.city}
                    </Tag>
                    <Tag styles={styles.tag}>
                        <Users style={styles.tagIcon}/>
                        {organization.employees}
                    </Tag>
                </div>
                {organization.website && <Button type="button" style={styles.button} onClick={handleWebUrlClick}>
                    <Website style={styles.icon}/>
                </Button>}
                {organization.facebookUrl && <Button type="button" style={styles.button} onClick={handleFacebookUrlClick}>
                    <Facebook style={styles.icon}/>
                </Button>}
                {organization.linkedInUrl && <Button type="button" style={styles.button} onClick={handleLinkedinUrlClick}>
                    <LinkedIn style={styles.icon}/>
                </Button>}
                {organization.twitterUrl && <Button type="button" style={styles.button} onClick={handleTwitterUrlClick}>
                    <Twitter style={styles.icon}/>
                </Button>}
                {organization.youtubeUrl && <Button type="button" style={styles.button} onClick={handleYoutubeUrlClick}>
                    <Youtube style={styles.icon}/>
                </Button>}
                {organization.address01 && <div style={styles.addressWrapper}>
                    <Address style={{...styles.icon, ...styles.icon.address}}/>
                    {organization.address01}
                </div>}
                <div style={styles.iconWrapper}>
                    {organization.isStartUpRegistered && <StartUpSlIcon style={styles.startupSL}/>}
                    {organization.disabilityFriendly && <DisableIcon style={{...styles.icon, ...styles.icon.disableIcon}}/>}
                    {organization.lgbtFriendly && <RainbowIcon style={styles.rainbowSL}/>}
                </div>
            </div>
            <hr style={styles.line} />
            <div style={styles.rowWrapper}>
                <div style={styles.description}>{organization.description}</div>
            </div>
            <div style={styles.rowWrapper}>
                {/* <Button type="button" style={styles.favouriteButton} onClick={handleFavouriteButtonClick}>
                    {props.buttonText}
                    <Heart style={styles.favouriteButton.icon}/>
                </Button> */}
                {/* temporally hide notify buttons */}
                {/* <Button type="button" style={styles.notifyButton} onClick={handleNotifyButtonClick}>
                    <Notification style={styles.notifyButton.icon}/>
                </Button> */}
            </div>
        </div>
    );
}