export const verticalBanner = {
    mobile: {
        wrapper: {
            borderRadius: "0.8vw",            
            overflow: "hidden",
            cursor: "pointer"
        },
        banner: {
            width: "100%"
        },
        dots: {             
            paddingLeft: "unset",
            marginTop: "-3vw" 
        }
    },
    desktop: {                               
        wrapper: {
            display: "block"
        },
        banner: {
            height: "50.41vw"
        },
        googleBanner: {
            display: "block",    
            height: "50.42vw"
        }
    }
}