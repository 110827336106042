import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const candidateCheckoutPage = {
    mobile: {
        display: "flex" as "flex",    
        flexDirection: "row" as "row",
        position: "relative" as "relative",
        top: "0",
        right: "0",        
        width: "100%",
        zIndex: "1",                    
        transition: "transform 1s ease-in-out",        
        transform: "translateX(0)",
        backdropLayer: {
            width: "10%",
            background: Color.DeepBlack,
            opacity: 0.4
        },
        mainContainer: {
            width: "90%"
        },
        container: {            
            margin: "3.57vh 4.83vw 0 4.83vw"
        },
        toggleWrapper: {
            display: "flex",
            flexDirection: "column" as "column"          
        },        
        leftWrapper: {

        },
        rightWrapper: {
            
        },        
        closeIcon: {
            width: "6.04vw",
            height: "6.04vw"                     
        },
        title: {
            fontSize: "6.04vw",
            color: Color.LightBlack,
            fontWeight: "bold" as "bold",
            margin: "14.49vw 0 9.66vw 0"
        },
        subTitle: {
            fontSize: "3.62vw",
            color: Color.Black,
            fontWeight: "bold" as "bold"            
        },
        input: {
            height: "12.08vw",
            fontSize: "3.86vw",
            fontFamily: Font.Poppins,
            marginTop: "1.67vh"
        },
        promoWrapper: {
            height: "21.10vw",
            borderRadius: "2.41vw",
            border: "0.24vw solid " + Color.Gainsboro,
            padding: "4.83vw",
            marginTop: "4.83vw"
        },
        codeWrapper: {
            display: "flex",
            flexDirection: "row" as "row",
            margin: "2.65vw 0 0 0",
            width: "100%"
        },
        promoInput: {
            height: "12.07vw",
            margin: "unset",
            borderRadius: "2.41vw"
        },
        promoInvalid: {
            height: "24.80vw",
            border: "0.24vw solid " + Color.Red,
            backgroundColor: Color.Linen
        },
        promoInvalidMessage: {
            fontSize: "3.38vw",
            color: Color.Red,
            marginTop: "2.17vw"
        },
        rightMargin: {
            marginRight: 0
        },
        leftMargin: {
            marginLeft: 0
        },
        applyButton: {
            width: "22.70vw",
            height: "12.08vw",
            border: "0.48vw solid " + Color.Blue,
            color: Color.Blue,
            background: "transparent",            
            fontWeight: "bold" as "bold",
            fontSize: "4.35vw",            
            borderRadius: "1.21vw",            
            marginLeft: "2.17vw",
            applied: {
                color: Color.DeepAsh,
                border: "0.48px solid" + Color.DeepAsh
            }
        },
        paymentWrapper: {
            margin: "9.66vw 0 0 0"
        },
        iconGride: {
            margin: "2.23vh 0 0 0"
        },
        paymentIcon: {
            maxWidth: "33.33vw",
            maxHeight: "12.07vw",
            margin: "0 1.93vw 2.41vw 0"
        },
        iconLine: {
            display: "none",
            width: "-webkit-fill-available",
            height: "0.18vh",
            backgroundColor: Color.Gainsboro,
            color: Color.Gainsboro,
            margin: "0 0 2.60vw 0",
            borderWidth: 0
        },
        checkoutButton: {                        
            height: "12.08vw",            
            margin: "7.73vw 0 12.07vw 0",        
            width: "81.88vw",                    
            color: Color.White,            
            fontWeight: "bold" as "bold",
            backgroundColor: Color.Green,
            border: "none",
            borderRadius: "2.41vw",            
            fontSize: "4.34vw",
            boxShadow: "1.21vw 1.21vw 12.08vw #00000029"            
        },        
        cart: {
            background: "transparent",            
            body: {
                display: "none"
            },
            heading: {
                fontSize: "1.56vw",                
                margin: "0 0 0.68vw 0",
                color: Color.LightBlack
            },            
            itemWrapper: {
                display: "flex" as "flex",
                height: "3.44vw",
                flexDirection: "row" as "row",
                border: "0.05vw solid " + Color.LightSilver,
                borderRadius: "0.52vw",
                alignItems: "center" as "center",
                background: Color.White,
                marginBottom: "0.31vw"
            },
            image: {
                width: "3.69vw",
                height: "100%",
                borderRadius: "0.52vw 0 0 0.52vw",
                objectFit: "cover",
                marginRight: "1.04vw"
            },
            name: {
                margin: 0,
                fontSize: "0.94vw"
            },
            description: {
                margin: 0,
                fontSize: "0.73vw"
            },
            cvPrice: {
                margin: "0 0.83vw 0 auto",
                fontSize: "0.94vw",
                fontWeight: "bold" as "bold",
                color: Color.SharpAsh
            },
            closeIcon: {
                margin: "0 1.04vw 0 0",
                cursor: "pointer",
                width: "0.83vw",
                height: "0.83vw"
            },
            line: {
                border: "none",
                height: "0.22vh",
                backgroundColor: Color.Gainsboro,
                margin: "4.29vh 0 1.23vh 0"
            },
            cvSummaryWrapper: {
                display: "flex",
                gap: "2.5vw",
            },
            cvSummary: {
                color: Color.SharpAsh,
                fontSize: "3.38vw",
                paddingTop: "0vw"
            },
            label: {
                display: "flex" as "flex",
                fontSize: "4.35vw",
                height: "5.83vw",
                margin: 0
            },
            netLabel: {
                display: "flex" as "flex",
                fontSize: "4.35vw",
                alignItems: "center" as "center",
                fontWeight: "bold" as "bold",
                margin: "3.83vw 0 0 0"
            },
            boldLabel: {
                fontWeight: "bold" as "bold"
            },
            price: {                    
                fontSize: "4.35vw",
                fontWeight: "bold" as "bold",
                marginLeft: "auto"
            },
            boldPrice: {
                fontSize: "6.76vw"
            },
            netPrice: {                    
                fontSize: "6.76vw",
                fontWeight: "bold" as "bold",
                marginLeft: "auto"
            },
            emptyCart: {
                width: "-webkit-fill-available"
            }
        },
        premiumCard: {            
            borderRadius :"2.42vw",
            border: "0.24vw solid " + Color.HarvestGold,
            backgroundColor: Color.OldLace,
            paddingBottom: "2.56vh",
            crownIcon: {
                width: "6.52vw",
                height: "2.34vh",
                fill: Color.HarvestGold,
                margin: "2.56vh 2.17vw 0 3.86vw"
            },
            crownText: {
                fontSize: "4.83vw",
                fontWeight: "bold" as "bold",
                color: Color.HarvestGold
            },
            subscription: {
                fontSize: "4.35vw",                
                margin: "1.12vh 0 0 3.86vw"
            },
            time: {
                fontSize: "3.38vw",                
                margin: "0 0 0 3.86vw"
            },
            price: {
                fontSize: "5.79vw",
                fontWeight: "bold" as "bold",
                color: Color.SharpAsh,
                float: "right",
                margin: "-1.45vh 7.73vw 0 0"
            }
        }     
    },
    desktop: {
        position: "relative",
        boxShadow: "unset",            
        transition: "unset",        
        transform: "unset",
        backdropLayer: {
            display: "none"
        },
        mainContainer: {
            width: "100%"
        },
        container: {     
            position: "relative",
            margin: "4.14vw 7% 11.39vw 7%",
            zIndex: 1
        },
        toggleWrapper: {
            flexDirection: "row" as "row"          
        },
        leftWrapper: {
            width: "50.93vw"
        },
        rightWrapper: {
            width: "27.92vw",
            paddingLeft: "6.56vw",
            marginLeft: "auto"
        },
        closeIcon: {
            display: "none"              
        },
        title: {
            display: "none"
        },
        subTitle: {
            fontSize: "1.25vw",
            color: Color.LightBlack,
            margin: 0,
            promoTitle: {
                width: "10.2vw",
                height: "2.86vw"
            }
        },
        input: {
            height: "3.65vw",
            fontSize: "0.93vw",
            paddingLeft: "1.56vw",
            marginTop: "1.85vh"
        },
        infoWrapper: {
            marginBottom: "1.04vw"
        },
        promoWrapper: {
            height: "4.20vw",
            border: "0.05vw solid " + Color.Gainsboro,
            borderRadius: "0.52vw",
            padding: "1.60vw 1.66vw 0 1.82vw",
            alignItems: "center" as "center",
            marginTop: "0"
        },
        codeWrapper: {
            margin: "auto 0 auto 2.92vw"
        },
        promoInput: {
            height: "2.60vw",                        
            width: "100%",
            borderRadius: "0.52vw",
            fontSize: "0.93vw"
        },
        promoInvalid: {
            height: "5.61vw",
            border: "0.05vw solid " + Color.Red
        },
        promoInvalidMessage: {
            fontSize: "0.94vw",
            margin: "0.63vw 0 0 11.50vw"
        },
        rightMargin: {
            marginRight: "0.42vw"
        },
        leftMargin: {
            marginLeft: "0.42vw"
        },
        applyButton: {
            height: "2.60vw",
            width: "8.07vw",
            border: "0.15vw solid " + Color.Blue,
            fontSize: "1.04vw",            
            borderRadius: "0.52vw",            
            margin: "auto 0 auto 0.83vw",
            applied: {
                border: "0.15vw solid " + Color.DeepAsh,
                margin: "auto 0 auto 0.85vw"
            }
        },
        paymentWrapper: {
            margin: "3.15vh 0 0 0"
        },
        iconGride: {
            margin: "1.94vh 0 0 0"
        },
        paymentIcon: {
            maxWidth: "8.90vw",
            maxHeight: "3.29vw",
            margin: "0 0.73vw 0 0"
        },
        iconLine: {
            display: "flex"            
        },
        checkoutButton: {                        
            width: "27.92vw",
            height: "2.60vw", 
            borderRadius: "0.52vw",
            margin: "2.24vw 0 0 0",            
            fontSize: "1.04vw",
            boxShadow: "0.26vw 0.26vw 2.60vw #00000029"            
        },
        cart: {
            background: Color.LightAsh,
            borderRadius: "0.52vw",
            padding: "2.22vh 1.25vw",            
            body: {
                display: "block"
            },
            line: {
                height: "0.18vh",
                margin: "1.89vw 0",
                border: "0.10vw solid" + Color.Gainsboro
            },
            cvSummaryWrapper: {
                display: "none"
            },
            cvSummary: {
                display: "none"
            },
            cvPriceWrapper: {
                display: "none"
            },
            label: {
                fontSize: "0.94vw",
                height: "1.20vw",
                alignItems: "center"
            },
            netLabel: {
                fontSize: "0.94vw",
                margin: "1.85vh 0 0 0"
            },            
            price: {                    
                fontSize: "0.94vw"
            },
            boldPrice: {
                fontSize: "1.46vw"
            },
            netPrice: {                    
                fontSize: "1.46vw"
            }
        },
        premiumCard: {
            borderRadius :"0.52vw",
            border: "0.05vw solid " + Color.HarvestGold,
            marginBottom: "2.13vh",
            crownIcon: {
                width: "1.40vw",
                height: "1.13vw",
                margin: "1.19vw 0.47vw 0 1.25vw"
            },
            crownText: {
                fontSize: "1.04vw",
                marginTop: "1.19vw"
            },
            subscription: {
                fontSize: "0.94vw",
                margin: "0.92vh 0 0 1.25vw"
            },
            time: {
                fontSize: "0.73vw",
                margin: "0 0 0 1.25vw"
            },
            price: {
                fontSize: "1.25vw",
                margin: "-1.20vh 1.66vw 0 0"
            }
        } 
    }
}