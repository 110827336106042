import React, { useContext, useEffect, useState, useRef } from 'react'
import { useMediaQuery } from 'react-responsive';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { LevelIndicator } from '../components/CandidateProfile/LevelIndicator';
import { MyInformation } from '../components/CandidateProfile/MyInformation';
import { PersonalDetails } from '../components/CandidateProfile/PersonalDetails';
import { StatusOfApplications } from '../components/CandidateProfile/StatusOfApplications';
import { CandidateSavedJobs } from '../components/Shared/CandidateSavedJobs';
import { Footer } from '../components/Shared/Footer';
import { GetInTouch } from '../components/Shared/GetInTouch';
import { Header } from '../components/Shared/Header';
import { HorizontalBanner } from '../components/Shared/HorizontalBanner';
import { Tabs } from '../components/Shared/Tabs';
import { Candidate } from '../models/Candidate';
import { TabItem } from '../models/TabItem';
import { AuthContext } from '../providers/AuthContextProvider';
import { StyleContext } from '../providers/StyleContextProvider';
import { CandidateProfilePageTexts as Texts } from '../helpers/LayoutTexts';
import { DraftApplications } from '../components/CandidateProfile/DraftApplications';
import { addNewCVToProfile, getCandidate, getCandidateProfile } from '../services/CandidateService';
import { CandidateProfileModel } from '../models/CandidateProfileModel';
import useErrorPage from '../hooks/useErrorPage';
import { CVPreviewer } from '../components/CandidateProfile/CVPreviewer';
import Dropzone from 'react-dropzone';
import { ReactComponent as FileUpload } from '../assets/svg/file-upload.svg';
import { Helmet } from 'react-helmet-async';
import { useAlert } from '../hooks/useAlert';
import { PopUpWindow } from '../components/Shared/PopUpWindow';
import { DocumentViewer } from '../components/Shared/DocumentViewer';
import { Button } from '../components/Shared/Buttons/Button';
import { ResponseModel } from '../models/ResponseModel';
import { getCandidateCVURL, validateCV } from '../helpers/Common';
import { LoggedCandidate } from '../models/LoggedCandidate';
import { VerticalBanner } from '../components/Shared/VerticalBanner';
import { PersonalDetailsLoader } from '../components/Loaders/PersonalDetailsLoader';

export const CandidateProfilePage: React.FC = () => {
    const navigate = useNavigate();
    let params = useParams();
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const loggedCandidate: LoggedCandidate | null = authContext.getLoggedCandidate();
    const [candidate, setCandidate] = useState<Candidate>();
    const [cvFile, setCVFile] = useState<string | File>();
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("candidateProfilePage"));
    const [tabItems, setTabItems] = useState<TabItem[]>([]);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [loading, setLoading] = useState<boolean>(true);    
    const [completedPercentage, setCompletedPercentage] = useState<number>(0);
    const [toErrorPage] = useErrorPage();
    const [uploadedCV, setUploadedCV] = useState<File>();    
    const { Alert, openAlert } = useAlert();
    const tabsRef = useRef<HTMLDivElement | null>(null);
    
    useEffect(() => {                     
        if(loggedCandidate === null) {  
            let message = "";
            switch (params.tab!) {
                case "myapplications":
                    message = "Please login to view the application statuses.";
                    break;
                case "updateprofile":
                    message = "Please login to update your profile.";
                    break;
                case "managesavedjobs":
                    message = "Please login to view saved jobs.";
                    break;
                case "drafts":
                    message = "Please login to view the draft applications.";
                    break;                
                default:
                    message = "Please login to access this page";
                    break;
            }
            const searchParams = { callbackUrl: "/candidate/" + params.tab!, message: message };
            navigate({
                pathname: '/candidates/login',
                search: `?${createSearchParams(searchParams)}`
            });
        } else {
            let newTabItems = [
                { label: "Status of Applications", value: "myapplications", component: <StatusOfApplications candidate={loggedCandidate!}/> },
                { label: "My Information", value: "updateprofile", component: <MyInformation candidate={loggedCandidate!} setCandidate={setCandidate} getCompletedPercentage={getCompletedPercentage}/> },
                { label: "Saved Items", value: "managesavedjobs", component: <div style={styles.saveWrapper}><CandidateSavedJobs candidate={loggedCandidate} title={"Saved Items"} loadMoreEnable={true}/></div> },
                { label: "Draft", value: "drafts", component: <DraftApplications candidate={loggedCandidate}/> },
                { label: "Online Courses", value: "onlineCourses" }
            ]
            setTabItems(newTabItems);
            setCVFile(getCandidateCVURL(loggedCandidate!.cvTranslationText!));
            getCandidateModel();
            getCompletedPercentage()     
        }  
    }, []);

    useEffect(() => {
        setStyles(styleContext.getComponentStyle("candidateProfilePage"));
    }, [isMobile]);

    useEffect(() => {
        if (tabItems.length > 0 && tabsRef.current) {
            const scrollTopPosition = tabsRef.current.offsetTop;
            window.scrollTo({
                top: scrollTopPosition,
                behavior: 'smooth'
            });
        }
    }, [!loading]);

    const getSelectedTab = (selectedTab: string) => {
        if(selectedTab === "onlineCourses") {
            window.open('https://school.xpress.jobs/', '_blank');            
        }
    }
    
    async function getCandidateModel() {
        setLoading(true);
        await getCandidate(loggedCandidate!.candidateId, loggedCandidate!.token)
        .then((candidateModel: Candidate) => {
            setCandidate(candidateModel);
            setLoading(false);
        })
        .catch((error) => {
            toErrorPage(error);
        });
    }

    async function getCompletedPercentage() {        
        await getCandidateProfile(loggedCandidate!.candidateId, loggedCandidate!.token)
        .then((profile: CandidateProfileModel) => {
            calculateCompletedPercentage(profile);
        })
        .catch((error) => {
            toErrorPage(error);
        });
    } 

    const calculateCompletedPercentage = (profile: CandidateProfileModel) => {
        let completedPropertyCount = 0;
        let totalPropertyCount = Object.keys(profile).length;
        for (const key in profile) {
            if (profile.hasOwnProperty(key)) {
                const value = profile[key];
                if (value !== null && value !== "") {
                    completedPropertyCount++;
                }
            }
        }
        let completedPercentage = (completedPropertyCount / totalPropertyCount) * 100;
        setCompletedPercentage(Math.floor(completedPercentage));
    }

    const handleCVFileChange = (uploadedFiles: File[]) => {   
        if(uploadedFiles.length > 0) {
            let tempUploadedCV = uploadedFiles[0];
            let cvValidation = validateCV(tempUploadedCV);
            if(cvValidation.valid){
                setUploadedCV(tempUploadedCV);                                                                                     
            }
            else{
                openAlert("error", cvValidation.invalidMessage!);
            }                        
        }
    }

    const handleCloseCVUploadPopup = () => {
        setUploadedCV(undefined);        
    }

    async function handlePostUploadedCV() {
        setLoading(true);
        var formData = new FormData();
        formData.append("candidateId", loggedCandidate?.candidateId.toString()!);
        formData.append("uploadedCV", uploadedCV!);
        await addNewCVToProfile(formData, loggedCandidate!.token)
        .then((response: ResponseModel) => {
            if(response.status){
                setCVFile(uploadedCV!);
                setUploadedCV(undefined);                
            }
            openAlert(response.status ? "success" : "error", response.message)
            setLoading(false);
        })
        .catch(error => {
            toErrorPage(error);
        })
    }

    return (
        <>
            <Helmet><title>Candidate Profile - XpressJobs</title></Helmet>
            <Header styleSheet="lowHeader" backButtonEnable={true} backButtonText={"Back"}/>
            <div style={styles.baseWrapper}>
                <div style={styles.leftWrapper}>
                    {loading ? <PersonalDetailsLoader candidate={loggedCandidate}/> : (candidate && <PersonalDetails styles={styles.personalDetails} candidate={candidate!} completedPercentage={completedPercentage} cvFile={cvFile} handleCVFileChange={handleCVFileChange} openAlert={openAlert}/>)}
                    {tabItems.length > 0 && <div ref={tabsRef}><Tabs styles={styles.tabs} path="/candidate/" tabItems={tabItems} selectedTab={params.tab!} getSelectedTab={getSelectedTab}/></div>}
                </div>
                {/* This is loading only desktop platform */}
                {!isMobile && <div style={styles.rightWrapper}>
                    {completedPercentage !== 100 && <LevelIndicator styles={styles.desktopProfileLevelIndicator} percentage={completedPercentage}/>}
                    {candidate && <CVPreviewer styles={styles.cvPreviewer} cvFile={cvFile} cvFileName={candidate.cvFileName}/>}
                    <Dropzone onDrop={acceptedFiles => handleCVFileChange(acceptedFiles)} multiple={false}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ style: styles.uploadCV })} className="center-content">
                                <input {...getInputProps()} />
                                <div style={styles.uploadIconWrapper} className="center-content"><FileUpload style={styles.uploadIcon} /></div>
                                <div style={styles.uploadCVTexts}>
                                    <div>{Texts.addNewCV}</div>
                                    <div style={styles.uploadCVMaxFileSize}>{Texts.fileSize}</div>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                    <div style={styles.verticalBanner}>
                        <VerticalBanner adsenseClient={Texts.adClient} adsenseSlot={Texts.verticalAdSlot}/>
                    </div>
                </div>}
                <PopUpWindow styles={styles.cvPreviewer.popup} open={uploadedCV !== undefined} toggle={handleCloseCVUploadPopup} closeCircle={true}>
                    <DocumentViewer document={uploadedCV!} styles={styles.cvPreviewer.addDocument}/>
                    <Button disabled={loading} style={styles.cvPreviewer.addCVButton} onClick={handlePostUploadedCV}>Add CV</Button>
                </PopUpWindow>
            </div>            
            <div style={styles.horizontalBanner}>
                <HorizontalBanner adsenseClient={Texts.adClient} adsenseSlotOne={Texts.horizontalAdSlot}/>
            </div>
            <GetInTouch/>
            <Footer/>
            <Alert/>
        </>
    )
}