import React, { useContext, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { createSearchParams, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ApplyForm } from '../components/JobApply/ApplyForm';
import { ApplyStatus } from '../components/JobApply/ApplyStatus';
import { QuestionForm } from '../components/JobApply/QuestionForm';
import { Footer } from '../components/Shared/Footer';
import { GetInTouch } from '../components/Shared/GetInTouch';
import { Header } from '../components/Shared/Header'
import { JobOrganizationCard } from '../components/Shared/JobOrganizationCard';
import { VerticalJobDetailCard } from '../components/Shared/VerticalJobDetailCard';
import { JobView } from '../models/JobView';
import { StyleContext } from '../providers/StyleContextProvider';
import { getPublishedJob, postJobApplyQuestions, postPreApplication } from '../services/JobService';
import { JobApplyLoader } from '../components/Loaders/JobApplyLoader';
import { AuthContext } from '../providers/AuthContextProvider';
import { ApplyComplete } from '../components/JobApply/ApplyComplete';
import { postJobApply } from '../services/JobService';
import { ResponseModel } from '../models/ResponseModel';
import { JobApplyModel } from '../models/JobApplyModel';
import { JobApplyQuestionModel } from '../models/JobQuestion';
import useErrorPage from '../hooks/useErrorPage';
import useSaveJob from '../hooks/useSaveJob';
import { useAlert } from '../hooks/useAlert';
import { Transition } from '../components/Shared/Transition';
import { LoggedCandidate } from '../models/LoggedCandidate';

export const JobApplyPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const params = useParams();
    const location = useLocation();
    const jobId = +params.jobId!;
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const candidate: LoggedCandidate | null = authContext.getLoggedCandidate();
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("jobApplyPage"));
    const [jobView, setJobView] = useState<JobView>();
    const [hasFiltering, setHasFiltering] = useState<boolean>(false);
    const [isDraft] = useState<boolean>(searchParams.get("isDraft") === "true");
    const [isDraftCV] = useState<boolean>(searchParams.get("isDraftCV") === "true");
    const [isDraftCompleted, setIsDraftCompleted] = useState<boolean>();
    const [applyStage, setApplyStage] = useState<number>(searchParams.get("stage") === null ? 1 : +searchParams.get("stage")!); // applying = 1, screening = 2, applied = 3 
    const [preApplicationId] = useState<string | null>(searchParams.get("preApplicationId"));
    const [loading, setLoading] = useState<boolean>(true);     
    const draftEnable = searchParams.get("preApplicationId") === null;
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [toErrorPage] = useErrorPage();
    const {saveCandidateJob, isSavedJob} = useSaveJob();
    const [isSaved, setIsSaved] = useState<boolean>(isSavedJob(jobId));
    const {Alert, openAlert} = useAlert();    

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("jobApplyPage"));
    }, [isMobile]);

    useEffect(() => {  
        if(candidate === null) {   
            const params = { callbackUrl: location.pathname + location.search, message: "Please login or register to apply this job." };
            navigate({
                pathname: '/candidates/login',
                search: `?${createSearchParams(params)}`
            });            
        }       
        getJob();
    }, []);

    async function getJob() {
        await getPublishedJob(jobId)
        .then((publishedJob: JobView) => {
            if(publishedJob) {
                if(preApplicationId === null && publishedJob.jobItem.isPreApplicationFormEnabled) {
                    navigate("/jobs/preapplicationform/" + jobId);
                } else {
                    setJobView(publishedJob); 
                    setLoading(false);
                    setHasFiltering(publishedJob.hasFiltering);
                }            
            } else {            
                navigate('/jobs');
            }
        })
        .catch((error) => {            
            toErrorPage(error);
        });
    }

    async function handleJobApply(formInputs: JobApplyModel, uploadedCV: File, saveAsDraft: boolean) {   
        setLoading(true);
        const formData = new FormData();        
        formData.append("uploadedCV", uploadedCV!);
        if(preApplicationId !== null) {
            formInputs.PreApplicationId = +preApplicationId;
            formData.append("jobApplyForm", JSON.stringify(formInputs));
            await postPreApplication(formData)
            .then((applyResponse: ResponseModel) => {
                setLoading(false);
                window.scrollTo(0, 0);
                if(applyResponse.status) {                    
                    setApplyStage(3);                        
                } else {
                    openAlert("error", applyResponse.message);
                }
            })
            .catch((error) => {            
                toErrorPage(error);
            });
        } 
        else {        
            formData.append("jobApplyForm", JSON.stringify(formInputs));
            await postJobApply(formData, saveAsDraft, candidate!.token)
            .then((applyResponse: ResponseModel) => {
                setLoading(false);
                window.scrollTo(0, 0);
                if(applyResponse.status) { 
                    if(saveAsDraft) {
                        setIsDraftCompleted(true);
                        openAlert("success", applyResponse.message);
                    }           
                    if(applyResponse.message === "HasJobFiltering") {
                        setApplyStage(2);
                    } else {
                        setApplyStage(3);
                    }                    
                } else {
                    openAlert("error", applyResponse.message);
                }
            })
            .catch((error) => {            
                toErrorPage(error);
            }); 
        } 
    }

    async function handleQuestionSubmit(jobApplyQuestionModel: JobApplyQuestionModel, saveAsDraft: boolean) {
        setLoading(true);
        await postJobApplyQuestions(jobApplyQuestionModel, saveAsDraft, candidate!.token)
        .then((questionSubmitResponse: ResponseModel) => {
            setLoading(false);
            window.scrollTo(0, 0);
            if(questionSubmitResponse.status) {    
                if(saveAsDraft) {
                    setIsDraftCompleted(true);
                    openAlert("success", questionSubmitResponse.message);
                }
                setApplyStage(3);                              
            } else {
                openAlert("error", questionSubmitResponse.message);
            }
        })
        .catch((error) => {            
            toErrorPage(error);
        });
    }

    const saveJob = (jobId: number) => {
        saveCandidateJob(jobId, isSaved);
        setIsSaved(!isSaved);
    }

    return (
        <>
            <Header styleSheet="mediumHeader" backButtonEnable={true} backButtonText="Back"/> 
            <ApplyStatus styles={styles.applyStatus} applyStage={applyStage} hasFiltering={hasFiltering} />
            {loading ? <JobApplyLoader/> :
            <div style={styles.mainWrapper}>
                {applyStage === 3 ? <ApplyComplete styles={styles.applyComplete} isDraftCompleted={isDraftCompleted}/> : 
                <>  
                    <div style={styles.rightWrapper}>
                        <Transition>
                            <VerticalJobDetailCard styles={{...styles.verticalJobDetailCard, ...(applyStage === 2 && styles.verticalJobDetailCard.hideSummary)}} applyEnable={false} job={jobView!.jobItem} isSaved={isSaved} education={jobView!.education} experience={jobView!.experience} salaryRange={jobView!.salaryRange} saveJob={saveJob}/>
                        </Transition>
                        <Transition><JobOrganizationCard  organization={jobView!.organization} styles={styles.organizationCard} buttonText={"ADD TO FAVOURITE"}/></Transition>
                    </div>
                    <Transition> 
                        <div style={styles.leftWrapper}>
                            {applyStage === 1 ? <ApplyForm styles={styles.applyForm} jobId={jobId} candidate={candidate!} draftEnable={draftEnable} isDraft={isDraft} isDraftCV={isDraftCV} handleApply={handleJobApply} openAlert={openAlert} isMobile={isMobile}/>
                            : <QuestionForm styles={styles.questionForm} jobId={jobId} candidate={candidate!} isDraft={isDraft} handleQuestionSubmit={handleQuestionSubmit}/>}
                        </div>
                    </Transition>
                </>}
            </div>}            
            <GetInTouch/>
            <Footer/>
            <Alert/>
        </> 
    )
}
