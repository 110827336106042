import React, { useContext, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { ApplyComplete } from '../components/JobApply/ApplyComplete';
import { CvLessApplyStatus } from '../components/JobApply/CvLessApplyStatus';
import { PreApplyForm } from '../components/JobApply/PreApplyForm';
import { JobApplyLoader } from '../components/Loaders/JobApplyLoader';
import { Footer } from '../components/Shared/Footer';
import { GetInTouch } from '../components/Shared/GetInTouch';
import { Header } from '../components/Shared/Header';
import { JobOrganizationCard } from '../components/Shared/JobOrganizationCard';
import { VerticalJobDetailCard } from '../components/Shared/VerticalJobDetailCard';
import { useAlert } from '../hooks/useAlert';
import useErrorPage from '../hooks/useErrorPage';
import useSaveJob from '../hooks/useSaveJob';
import { JobApplyQuestion, JobApplyQuestionModel } from '../models/JobQuestion';
import { JobView } from '../models/JobView';
import { ResponseModel } from '../models/ResponseModel';
import { AuthContext } from '../providers/AuthContextProvider';
import { StyleContext } from '../providers/StyleContextProvider';
import { getPreApplyQuestions, getPublishedJob, postPreApplication, postPreApplyQuestions } from '../services/JobService';
import { Transition } from '../components/Shared/Transition';
import { PopUpWindow } from '../components/Shared/PopUpWindow';
import { Button } from '../components/Shared/Buttons/Button';
import { JobApplyPageTexts as Texts } from '../helpers/LayoutTexts'
import { JobApplyModel } from '../models/JobApplyModel';
import { LoggedCandidate } from '../models/LoggedCandidate';

export const CVLessJobApplyPage: React.FC = () => {
    let params = useParams();
    const jobId: number = +params.jobId!;
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("jobApplyPage"));
    const authContext = useContext(AuthContext);
    const candidate: LoggedCandidate | null = authContext.getLoggedCandidate();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" }); 
    const [jobView, setJobView] = useState<JobView>();
    const [applyStage, setApplyStage] = useState<number>(1); // screening = 1, applied = 2
    const [loading, setLoading] = useState<boolean>(true);
    const preApplicationIdRef = useRef(0);
    const [preApplyQuestions, setPreApplyQuestions] = useState<JobApplyQuestion[]>([]);
    const [toErrorPage] = useErrorPage();
    const {saveCandidateJob, isSavedJob} = useSaveJob();
    const [isSaved, setIsSaved] = useState<boolean>(isSavedJob(jobId));
    const {Alert, openAlert} = useAlert();
    const openDialogRef = useRef(false);    
    const isMountedRef = useRef<boolean>(true);
    const retryCountRef = useRef<number>(1);
    const maxRetries = 3;

    useEffect(() => {
        setStyles(styleContext.getComponentStyle("jobApplyPage"));
    }, [isMobile]);

    useEffect(() => {        
        getJob();
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {    
            if(openDialogRef.current) {
                closeDialog();                
            }                                
        };    
        window.addEventListener('beforeunload', handleBeforeUnload);    
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            isMountedRef.current = false;
        };
    }, []);
    
    async function getJob() {
        await getPublishedJob(jobId)
        .then((publishedJob: JobView) => {
            if(publishedJob) {
                if(publishedJob.jobItem.isPreApplicationFormEnabled) {
                    setJobView(publishedJob); 
                    getPreApplyQuestionList();
                } else {
                    navigate("/jobs/apply/" + jobId);
                }            
            } else {            
                navigate('/jobs');
            }
        })
        .catch((error) => {            
            toErrorPage(error);
        });
    }

    async function handlePreApplySubmit(jobApplyQuestionModel: JobApplyQuestionModel) {
        setLoading(true);
        await postPreApplyQuestions(jobApplyQuestionModel)
        .then((response: ResponseModel) => {
            if(response.status) {                
                preApplicationIdRef.current = response.data;
                openDialogRef.current = true;
            } else {
                openAlert("error", response.message);
            }
            setLoading(false);
        })
        .catch((error) => {            
            toErrorPage(error);
        }); 
    }
    
    async function getPreApplyQuestionList() {
        await getPreApplyQuestions(jobId)
        .then((preApplyQuestionList: JobApplyQuestion[]) => {
            if(candidate !== null) {
                preApplyQuestionList.find(x => x.question === "Name")!.openAnswer = candidate.forename + " " + candidate.surname;
                preApplyQuestionList.find(x => x.question === "Name")!.candidateAnswer = candidate.forename + " " + candidate.surname;
                preApplyQuestionList.find(x => x.question === "Email")!.openAnswer = candidate.email;
                preApplyQuestionList.find(x => x.question === "Email")!.candidateAnswer = candidate.email;
            }
            setPreApplyQuestions(preApplyQuestionList);
            setLoading(false);
        })
        .catch((error) => {            
            if(isMountedRef.current) {                   
                if (retryCountRef.current < maxRetries) {
                    // Retry the API call after 1 second
                    retryCountRef.current = retryCountRef.current + 1;
                    setTimeout(getPreApplyQuestionList, 1000);
                } 
                else {
                    // Redirect to error page after max retries
                    toErrorPage(error);
                }  
            }
        }); 
    }    

    const saveJob = (jobId: number) => {
        saveCandidateJob(jobId, isSaved);
        setIsSaved(!isSaved);
    }
    
    async function closeDialog() {
        openDialogRef.current = false;
        setLoading(true);
        let model: JobApplyModel = {
            JobId: jobId,
            CandidateId: 0,
            PreApplicationId: preApplicationIdRef.current,
            FullName: "",
            Email: ""
        }
        const formData = new FormData();
        formData.append("jobApplyForm", JSON.stringify(model));        
        postPreApplication(formData)
        .then((applyResponse: ResponseModel) => { })
        .catch((error) => {            
            toErrorPage(error);
        });  
        setLoading(false);
        window.scrollTo(0, 0);
        setApplyStage(2);
    }

    const uploadCV = () => {
        if (candidate === null) {
            const params = { callbackUrl: "/jobs/apply/" + jobView?.jobId + "?preApplicationId=" + preApplicationIdRef.current, message: "Please login or register to upload your CV." };
            navigate({
                pathname: '/candidates/login',
                search: `?${createSearchParams(params)}`
            });
        } else {
            navigate("/jobs/apply/" + jobView?.jobId + "?preApplicationId=" + preApplicationIdRef.current);
        }
    }

    return(
        <>
            <Header styleSheet="mediumHeader" backButtonEnable={true} backButtonText="Back"/>
            <CvLessApplyStatus styles={styles.applyStatus} applyStage={applyStage}/>
            {loading ? <JobApplyLoader /> : 
            <div style={styles.mainWrapper}>
                {applyStage === 2 ? <ApplyComplete styles={styles.applyComplete}/> : 
                <>
                    <div style={styles.rightWrapper}>
                        <Transition>
                            <VerticalJobDetailCard styles={styles.verticalJobDetailCard} job={jobView!.jobItem} isSaved={isSaved} applyEnable={false} education={jobView!.education} experience={jobView!.experience} salaryRange={jobView!.salaryRange} saveJob={saveJob}/>
                        </Transition>
                        <Transition>
                            <JobOrganizationCard  organization={jobView!.organization} styles={styles.organizationCard} buttonText={"ADD TO FAVOURITE"}/>
                        </Transition>
                    </div>
                    <div style={{...styles.leftWrapper, ...styles.reducePadding}}>
                        <PreApplyForm styles={styles.preApplyForm} jobId={jobId} preApplyQuestions={preApplyQuestions} handlePreApplySubmit={handlePreApplySubmit} openAlert={openAlert}/>
                    </div>
                </>}
            </div>}
            <GetInTouch/>
            <Footer/>
            <Alert/>
            <PopUpWindow styles={styles.dialog} open={openDialogRef.current} toggle={closeDialog}>                  
                <p style={styles.dialog.message}>{Texts.cvLessCVUpload}</p>
                <div style={styles.dialog.buttons}>
                    <Button style={styles.dialog.close} type="button" onClick={closeDialog}>{"Close"}</Button>
                    <Button style={styles.dialog.redirect} type="button" onClick={uploadCV}>{"UPLOAD MY CV"}</Button>                        
                </div> 
            </PopUpWindow>
        </> 
    )
}