import { useContext, useEffect, useState } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { StyleContext } from "../providers/StyleContextProvider";
import { useMediaQuery } from "react-responsive";
import { finishQuizRequest, postQuizResponse, validateQuiz } from "../services/QuizService";
import useErrorPage from "../hooks/useErrorPage";
import { ResponseModel } from "../models/ResponseModel";
import { Header } from "../components/Shared/Header";
import { QuizModel } from "../models/QuizModel";
import { GetInTouch } from "../components/Shared/GetInTouch";
import { Footer } from "../components/Shared/Footer";
import { Button } from "../components/Shared/Buttons/Button";
import { QuizQuestion } from "../models/QuizQuestion";
import { QuizForm } from "../components/Quiz/QuizForm";
import { useAlert } from "../hooks/useAlert";
import { QuizApplyModel } from "../models/QuizApplyModel";
import { ReactComponent as SuccessfullyApplied } from '../assets/svg/successfully-applied.svg'
import { QuizComplete } from "../components/Quiz/QuizComplete";
import { InterviewDateForm } from "../components/Quiz/InterviewDateForm";
import { QuizLoader } from "../components/Loaders/QuizLoader";
import { Helmet } from "react-helmet-async";

export const QuizPage: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("quizPage"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    let key: string = searchParams.get("id")!;
    const [quizStage, setQuizStage] = useState<number>(2); // invalid = 1, valid = 2, questions = 3, interview = 4, finished = 5
    const [quiz, setQuiz] = useState<QuizModel>();
    const [quizQuestions, setQuizQuestions] = useState<QuizQuestion[]>([]);
    const [interviewDates, setInterviewDates] = useState<string[]>([]);
    const [message, setMessage] = useState<string>("");
    const [resultContent, setResultContent] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);
    const {Alert, openAlert} = useAlert(); 
    const [toErrorPage] = useErrorPage();

    useEffect(() => {
        setStyles(styleContext.getComponentStyle("quizPage"));    
    }, [isMobile]);

    useEffect(() => {
        validate();
    }, [key]);
       
    useEffect(() => {
        window.scrollTo(0, 0);                                   
    }, [resultContent])

    async function validate() {        
        await validateQuiz(key)
        .then((response: ResponseModel) => {
            setQuiz(response.data);
            if(response.status) {
                setQuizStage(2);
            }
            else {   
                setQuizStage(1);             
                setMessage(response.message);
            }
            setLoading(false);
        })
        .catch((error) => {
            toErrorPage(error);
        });
    }

    async function startQuiz() {        
        await postQuizResponse(quiz!)
        .then((response: ResponseModel) => {
            if(response.status) {
                setQuizQuestions(response.data);
                setQuizStage(3);
            }
            else {            
                setQuizStage(1);             
                setMessage(response.message);
            }
        })
        .catch((error) => {            
            toErrorPage(error);
        });        
    }

    const startNextQuiz = (id: string) => {
        const params = { id: id }; 
        const searchParams = createSearchParams(params);        
        const newUrl = `?${searchParams}`;            
        navigate(newUrl);        
    }

    const openInterviewDates = (dates: string[]) => {        
        setInterviewDates(dates);
        setQuizStage(4);
    }

    async function finishQuiz(quizApplyModel: QuizApplyModel) {        
        await finishQuizRequest(quizApplyModel)
        .then((response: ResponseModel) => {
            let content: any;
            if(response.status) {
                if(response.message === "HasQuiz") {
                    content = <>
                        <SuccessfullyApplied style={styles.complete.image}/>
                        <h3 style={styles.complete.title}>Congratulations! You Have Successfully Submitted The Quiz</h3>
                        <p style={styles.complete.description}>Please click <span style={styles.complete.link} onClick={() => startNextQuiz(response.data)}>here</span> to proceed.</p>
                    </>
                }
                else if(response.message === "HasInterviewDates") {
                    content = <>
                        <SuccessfullyApplied style={styles.complete.image}/>
                        <h3 style={styles.complete.title}>Congratulations! You Have Successfully Submitted The Quiz</h3>
                        <p style={styles.complete.description}>Please click <span style={styles.complete.link} onClick={() => openInterviewDates(response.data)}>here</span> to proceed.</p>
                    </>
                }
                else if(response.message === "Finished" || response.message === "QuizFailed") {
                    content = <>
                        <SuccessfullyApplied style={styles.complete.image}/>
                        <h3 style={styles.complete.title}>Congratulations! You Have Successfully Submitted The Quiz</h3>
                        <p style={styles.complete.description}>Your results will be sent to the organization</p>                         
                    </>
                }
                setResultContent(content);
                setQuizStage(5);
            }                
        })
        .catch((error) => {            
            toErrorPage(error);
        });        
    };

    return (
        <>
            <Helmet><title>{quiz?.quizName || 'XpressJobs'} - XpressJobs </title></Helmet>
            <Header styleSheet={(quizStage === 3) ? "lowestHeader" : "lowHeader"} searchEnable={false} heading={quiz ? quiz.quizName : ""}/>
            <div style={styles.container}>
                {quizStage === 1 && <div style={styles.complete.container}> 
                    {/* Replace this image with un-successfull image */}                       
                    <SuccessfullyApplied style={styles.complete.image}/>
                    <h3 style={styles.complete.title}>{message}</h3>
                    <div style={styles.buttonWrapper}>
                        <Button style={styles.complete.button} onClick={() => navigate("/candidate/myapplications")}>VIEW MY APPLICATIONS</Button> 
                        <Button style={{...styles.complete.button, ...styles.complete.button.back}} onClick={() => navigate('/')}>GO BACK TO HOME</Button>
                    </div>
                </div>}
                {quizStage === 2 && <>
                    {loading ? <QuizLoader/> : 
                    <div style={styles.valid}>
                        <h3 style={styles.valid.title}>Please read the Instructions carefully before starting the quiz</h3>                    
                        <ul style={styles.valid.list}>
                            <li style={styles.valid.item}>This is a one time quiz. You can't answer multiple times.</li>
                            <li style={styles.valid.item}>The quiz consists of <span style={styles.valid.bold}>{quiz?.questionCount}</span> questions</li>
                            <li style={styles.valid.item}>The quiz consists of <span style={styles.valid.bold}>{quiz?.pageCount}</span> pages</li>
                            <li style={styles.valid.item}>You can not go back to the previous page, be sure to answer all the questions before you click next</li>
                            <li style={styles.valid.item}>The duration is <span style={styles.valid.bold}>{(quiz?.duration! / 60)}</span> minutes</li>                            
                        </ul>
                        <Button style={styles.button} onClick={startQuiz}>START QUIZ</Button>                    
                    </div>}
                </>}
                {quizStage === 3 && <QuizForm styles={styles.questions} quiz={quiz!} questions={quizQuestions} finishQuiz={finishQuiz} openAlert={openAlert}/>}
                {quizStage === 4 && <InterviewDateForm styles={styles} quiz={quiz!} interviewDates={interviewDates} openAlert={openAlert}/>}
                {quizStage === 5 && <QuizComplete styles={styles.complete} content={resultContent}/>}
            </div>
            <GetInTouch/>
            <Footer/>
            <Alert/>
        </>
    );
}