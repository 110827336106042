import Axios from "axios";
import { buildWebStorage, setupCache } from 'axios-cache-interceptor';
import { JobApplyQuestionModel } from "../models/JobQuestion";
import { SearchCriteria } from "../models/SearchCriteria";
import { getHost } from "../helpers/Common";

const instance = Axios.create();
const axios = setupCache(instance, { storage: buildWebStorage(localStorage, 'axios-cache:') });
const apiBaseUrl = getHost() + "api/";

export async function getPublishedJob(jobId: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "jobs/publishedJob", {
            params: {
                jobId: jobId
            },
            cache: false
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getRelatedJobs(jobId: number, recordsCount: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "jobs/relatedJobs", {
            params: {
                jobId: jobId,
                recordsCount: recordsCount
            },
            cache: false
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getSearchJobs(searchCriteria: SearchCriteria) {    
    return new Promise<any>((resolve, reject) => {
        axios        
        .get(apiBaseUrl + "jobs/searchJobs", {
            params: searchCriteria
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getCandidateRecommendedJobs(candidateId: number, preferredSectorIds: string, recordsCount: number, sortBy: string, token: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "jobs/candidateRecommendedJobs", {
            params: {
                candidateId: candidateId,
                preferredSectorIds: preferredSectorIds,
                sortBy: sortBy,
                recordsCount: recordsCount
            },
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            cache: {
                ttl: 1000 * 60 * 30 // 30 mins.
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getCandidateAppliedJobs(candidateId: number, recordsCount: number, token: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "jobs/candidateAppliedJobs", {
            params: {
                candidateId: candidateId,                
                recordsCount: recordsCount
            },
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            cache: false
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getCandidateSavedJobs(candidateId: number, page: number, pageSize: number, sortBy: string, token: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "jobs/candidateSavedJobs", {
            params: {
                page: page,
                pageSize: pageSize,
                candidateId: candidateId,
                sortBy: sortBy
            },
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            cache: false
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getAllSectors() {    
    return new Promise<any>((resolve, reject) => {
        axios        
        .get(apiBaseUrl + "jobs/allSectors", {           
            cache: {
                ttl: 1000 * 60 * 60 * 24 * 30 // 30 days.
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getSearchFilterOptions(byCVLess: boolean, byWalkIn: boolean) {    
    return new Promise<any>((resolve, reject) => {
        axios        
        .get(apiBaseUrl + "jobs/searchFilterOptions", {
            params: {
                byCVLess: byCVLess,
                byWalkIn: byWalkIn
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function updateJobRedirect(jobId: number) {    
    return new Promise<any>((resolve, reject) => {
        axios        
        .post(apiBaseUrl + "jobs/updateJobRedirect", "jobId=" + jobId )
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getJobApplyStatus(candidateId: number, jobId: number, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios        
        .get(apiBaseUrl + "jobs/jobApplyStatus", {
            params: {
                candidateId: candidateId,
                jobId: jobId
            },
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            cache: false
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postJobApply(formData: FormData, saveAsDraft: boolean, token: string) { 
    let url = "";
    if(saveAsDraft) {
        url = apiBaseUrl + "jobs/jobApplyDraft"
    } else {
        url = apiBaseUrl + "jobs/jobApply";
    }   
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization' : `Bearer ${token}`
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function getJobApplyQuestions(candidateId: number, jobId: number, isDraft: boolean, token: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "jobs/jobApplyQuestions", {            
            params: {
                candidateId: candidateId, 
                jobId: jobId,
                isDraft: isDraft
            },
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postJobApplyQuestions(jobApplyQuestionModel: JobApplyQuestionModel, saveAsDraft: boolean, token: string) { 
    let url = "";
    if(saveAsDraft) {
        url = apiBaseUrl + "jobs/jobApplyQuestionsDraft"
    } else {
        url = apiBaseUrl + "jobs/jobApplyQuestions";
    }   
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(url, jobApplyQuestionModel, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization' : `Bearer ${token}`
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function getPreApplyQuestions(jobId: number) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "jobs/preApplicationForm", {            
            params: {                
                jobId: jobId
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postPreApplyQuestions(jobApplyQuestionModel: JobApplyQuestionModel) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(apiBaseUrl + "jobs/preApplicationForm", jobApplyQuestionModel, {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function postPreApplication(formData: FormData) {        
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(apiBaseUrl + "jobs/preApplicationToRecruiter", formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function getApplicationStatus() {    
    return new Promise<any>((resolve, reject) => {
        axios        
        .get(apiBaseUrl + "jobs/applicationStatus", {           
            cache: {
                ttl: 1000 * 60 * 60 * 24 * 30 // 30 days.
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getOrganizationJobs(searchCriteria: SearchCriteria) {    
    return new Promise<any>((resolve, reject) => {
        axios        
        .get(apiBaseUrl + "jobs/organizationJobs", {
            params: searchCriteria,
            cache: false
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function updateJobViewCount(jobId: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(apiBaseUrl + "jobs/increaseJobViewCount", "jobId=" + jobId )
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}